import React from 'react'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import Map from '../../atoms/map/map'
import './modalPov.scss'

const ModalPov = ({ onClose }) => {
	return (
		<LayoutModal onClose={onClose}>
			<section className='f-modalpov'>
				<div className='f-modalpov-header'>Puntos de venta cerca de ti</div>
				<div className='f-modalpov-content'>
					<Map />
				</div>
			</section>
		</LayoutModal>
	)
}

export default ModalPov
