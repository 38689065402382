import { getSessionStorage, getLocalStorage } from './utilities-dom'

const _sessionStorage = getSessionStorage()
const _localStorage = getLocalStorage()

const getItemSS = key => {
	const item = _sessionStorage.getItem
		? _sessionStorage.getItem(key)
		: undefined
	try {
		return JSON.parse(item)
	} catch {
		return item
	}
}

const setItemSS = (key, value) => {
	if (typeof value === 'object') {
		_sessionStorage.setItem(key, JSON.stringify(value))
	} else {
		_sessionStorage.setItem(key, value)
	}
	return true
}

const removeItemSS = key => {
	_sessionStorage.removeItem(key)
}

const clearSS = () => {
	_localStorage.clear()
}

const getItemLS = key => {
	const item = _localStorage.getItem ? _localStorage.getItem(key) : undefined
	try {
		return JSON.parse(item)
	} catch {
		return item
	}
}

const setItemLS = (key, value) => {
	if (typeof value === 'object') {
		_localStorage.setItem(key, JSON.stringify(value))
	} else {
		_localStorage.setItem(key, value)
	}
	return true
}
const removeItemLS = key => {
	_localStorage.removeItem(key)
}

const clearLS = () => {
	_localStorage.clear()
}

export const sessionStorage = {
	getItem: getItemSS,
	setItem: setItemSS,
	removeItem: removeItemSS,
	clear: clearSS,
}

export const localStorage = {
	getItem: getItemLS,
	setItem: setItemLS,
	removeItem: removeItemLS,
	clear: clearLS,
}
