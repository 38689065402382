import React from 'react'
import { sessionStorage, localStorage } from './../state/utils/storage'

export default function useSessionState() {
	const [isLoggedUser, setLoggedUser] = React.useState(false)
	const [actualStorage, setActualStorage] = React.useState(0)
	const [isEffect, setIsEffect] = React.useState(false)
	const key = 'tokenData'
	React.useEffect(() => {
		if (!localStorage.getItem(key) && !sessionStorage.getItem(key)) {
			setLoggedUser(false)
		} else {
			if (localStorage.getItem(key)) {
				setActualStorage(0)
			} else if (sessionStorage.getItem(key)) {
				setActualStorage(1)
			}
			setLoggedUser(true)
		}
		setIsEffect(true)
	})

	return { isLoggedUser, actualStorage, isEffect }
}
