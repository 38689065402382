import React from 'react'

export default function useModalGeneric(initialState) {
	const [isModal, setShowModal] = React.useState(initialState)

	const showModal = () => setShowModal(true)
	const hideModal = () => setShowModal(false)

	return [isModal, showModal, hideModal]
}
