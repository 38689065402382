import React, { useState, useEffect } from 'react'
import './list-point.scss'

const ListPoint = ({ dataMap, setDefaulGeo, userPos }) => {
	const [points, setpoints] = useState(
		document.getElementsByClassName('f-mark-point')
	)

	useEffect(() => {
		setpoints(document.getElementsByClassName('f-mark-point'))
	}, [])

	const compare = (a, b) => {
		// Use toUpperCase() to ignore character casing
		const bandA = distance(userPos.lat, userPos.lng, a.lat, a.lng, 'K')
		const bandB = distance(userPos.lat, userPos.lng, b.lat, b.lng, 'K')

		let comparison = 0
		if (bandA > bandB) {
			comparison = 1
		} else if (bandA < bandB) {
			comparison = -1
		}
		return comparison
	}

	const clickAction = (index, data) => {
		points.item(index).click()
		setDefaulGeo({
			center: {
				lat: data.lat,
				lng: data.lng,
			},
			zoom: 17,
		})
	}

	const distance = (lat1, lon1, lat2, lon2, unit) => {
		if (lat1 === lat2 && lon1 === lon2) {
			return 0
		} else {
			const radlat1 = (Math.PI * lat1) / 180
			const radlat2 = (Math.PI * lat2) / 180
			const theta = lon1 - lon2
			const radtheta = (Math.PI * theta) / 180
			let dist =
				Math.sin(radlat1) * Math.sin(radlat2) +
				Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
			if (dist > 1) {
				dist = 1
			}
			dist = Math.acos(dist)
			dist = (dist * 180) / Math.PI
			dist = dist * 60 * 1.1515
			if (unit === 'K') {
				dist = dist * 1.609344
			}
			if (unit === 'N') {
				dist = dist * 0.8684
			}
			return dist
		}
	}

	return (
		<div className='f-horizontal-panel'>
			{dataMap.sort(compare).map((data, index) => (
				<div
					key={index}
					className='f-horizontal-panel-item'
					onClick={() => clickAction(index, data)}
				>
					<div className='f-horizontal-panel-item-cover'>
						<div className='f-horizontal-panel-item-cover-marker' />
						<span>
							{distance(
								userPos.lat,
								userPos.lng,
								data.lat,
								data.lng,
								'K'
							).toFixed(1)}{' '}
							km
						</span>
					</div>
					<div className='f-horizontal-panel-item-text'>
						<div className='f-horizontal-panel-item-text-name'>{data.name}</div>
						<div className='f-horizontal-panel-item-text-description'>
							{data.description}
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

export default ListPoint
