import React, { useState, useEffect } from 'react'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import './modal-retails.scss'
import useLocaleMode from '../../../hooks/useLocaleMode'
import Slider from 'react-slick'
import {
	addNoScroll,
	removeNoScroll,
} from '../../../state/utils/overflowHelper'
import { gothic, lonchmara } from '../../../_shared/Variables.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { pushGtagEvent, pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'

const ModalRetails = props => {
	const { paramRetails } = props
	const { locale } = useLocaleMode()
	const [scrollInitial, setScrollInitial] = useState(0)

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, next) => {
				// console.log("🚀 ~ file: modal-retails.jsx ~ line 34 ~ next", next[0] === '.')
				if (next && next[0] !== '.') {
					return <p className='f-retails-content-item-description'>{next}</p>
				} else {
					return null
				}
			},
		},
	}

	const query = useStaticQuery(graphql`
		query RETAILS {
			allContentfulTenaHome {
				nodes {
					node_locale
					retailsDeCompra {
						titulo
						logoDeRetail {
							fluid(maxWidth: 700, quality: 90) {
								src
								srcSet
								base64
								aspectRatio
								sizes
							}
							description
						}
						ctaRetail
						descripcionRetail {
							json
						}
						redireccionRetail
					}
				}
			}
		}
	`)

	const retailByCountry = query.allContentfulTenaHome.nodes.filter(
		node => node.node_locale === locale
	)[0]
	const isSlider = false
	const imgProducto =
		props.productInfo && props.productInfo.imagenProductoPrevisualizacion
			? props.productInfo.imagenProductoPrevisualizacion[props.ismobile ? 1 : 0]
			: { file: {} }

	const NextArrow = next => {
		const { onClick } = next
		return <span className='f-slider-control-next' onClick={onClick} />
	}

	const PrevArrow = prev => {
		const { onClick } = prev
		return <span className='f-slider-control-prev' onClick={onClick} />
	}


	const userInfo=dataLayerUserInfo()
	const dataLaterTiendas = (tienda) => {		
		let location=''
		if (typeof window !== 'undefined') {
			location= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Pop-ups',
		category: 'pop-up',
		action: 'click',
		label: 'Comprar',
		location,
		online_store:tienda, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}

	const settings = {
		arrows: true,
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '0',
		focusOnSelect: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	}

	const renderDrops = (drops, activeDrops) => {
		const nodeArray = []
		for (let index = 0; index < Number(drops); index++) {
			nodeArray.push(
				<svg key={index} width='24' height='35' viewBox='0 0 24 35'>
					<linearGradient id={'grad' + index} x1='0%' y1='0%' x2='0%' y2='100%'>
						<stop
							offset='50%'
							stopOpacity={1}
							stopColor={props.dropsColor ? props.dropsColor : gothic}
						/>
						<stop offset='50%' stopOpacity={1} stopColor={lonchmara} />
					</linearGradient>
					<path
						className={
							index < Number(activeDrops)
								? activeDrops % 1 === 0.5 &&
								  parseInt(index + 0.5) === parseInt(activeDrops)
									? 'f-drop--active-medium'
									: 'f-drop f-drop--active'
								: 'f-drop'
						}
						d='M16.68,36.747A11.919,11.919,0,0,1,5,24.586C5,15.465,16.68,2,16.68,2S28.36,15.465,28.36,24.586A11.919,11.919,0,0,1,16.68,36.747Z'
						transform='translate(-5 -2)'
						fill={
							activeDrops % 1 === 0.5 &&
							parseInt(index + 0.5) === parseInt(activeDrops)
								? `url(#grad${index})`
								: 'none'
						}
					/>
				</svg>
			)
		}
		return nodeArray
	}
	const gtagEventButton=(option)=>{
		const info={
			event:'add_to_cart',
				params:{	
					items: [{ 
					item_id: props.productInfo.codigoDeProducto,
					item_name: props.productInfo.nombreProducto,
					discount: 0,
					item_brand: 'Tena',
					affiliation: 'Tena Online',
					item_category: props.productInfo.dirigidoA[0].nombreDeEtiqueta,	
					item_variant: props.productInfo.linea[0]? props.productInfo.linea[0]?.nombreDeEtiqueta : undefined,
					currency:process.env.LOCALE,
					price:0,
					quantity:1,
					}],
					drip_degree: props.productInfo.tipoDeIncontinencia[0]? props.productInfo.tipoDeIncontinencia[0].nombreDeEtiqueta : undefined,
					option,	
					flow_type:'compra',	
					user_data: userInfo.user_data? userInfo.user_data: '',
					log_status: userInfo.log_status,			  		
				}            
		}
		return info
	}
	const dataLayerRetails = (retail) => {
		pushDataLayerEvent({
			event: "buy_now_go_to_retailer",
			retailer: retail,
  			single_item_id: props.productInfo.codigoDeProducto,
  			single_item_name: props.productInfo.nombreProducto,
		})
	}
	console.log(props);

	const listItems = paramRetails
		? paramRetails.map((retail, index) => {
				return (
					<div className='f-retails-content-item' key={index}>
						<Img
							className='f-retails-content-item-image'
							fluid={retail.logoDeRetail.fluid}
							alt={retail.logoDeRetail.description}
						/>
						{documentToReactComponents(retail.descripcionRetail.json, options)}
						<a
							className='f-button f-button--primary f-button-retail'
							target='_blank'
							rel='noreferrer noopener'
							onClick={() => {
								pushGtagEvent(gtagEventButton(retail.titulo))
								dataLaterTiendas(retail.titulo.split('>')[0])
								dataLayerRetails(retail.titulo.split('>')[0])
							}}
							// href={props.commercialPartners ? props.commercialPartners[index] : retail.redireccionRetail}>{retail.ctaRetail}</a>
							href={retail.redireccionRetail}
						>
							{retail.ctaRetail}
						</a>
					</div>
				)
		  })
		: retailByCountry &&
		  retailByCountry.retailsDeCompra.map((retail, index) => {
				return (
					<div className='f-retails-content-item' key={index}>
						<Img
							className='f-retails-content-item-image'
							fluid={retail.logoDeRetail.fluid}
							alt={retail.logoDeRetail.description}
						/>
						{documentToReactComponents(retail.descripcionRetail.json, options)}
						<a
							className='f-button f-button--primary f-button-retail'
							target='_blank'
							rel='noreferrer noopener'
							onClick={() =>{dataLayerRetails(retail.titulo)}}
							// href={props.commercialPartners ? props.commercialPartners[index] : retail.redireccionRetail}>{retail.ctaRetail}</a>
							href={retail.redireccionRetail}
						>
							{retail.ctaRetail}
						</a>
					</div>
				)
		  })

	useEffect(() => {
		setScrollInitial(window.scrollY)
		addNoScroll()
		return () => {
			removeNoScroll()
		}
	}, [])

	const cerrar = () => {
		window.scroll({
			top: scrollInitial,
			left: 0,
			behavior: 'smooth',
		})
		props.hideAction()
	}

	return (
		<LayoutModal>
			<div
				className={`f-modal-retails ${
					props.productInfo ? 'f-modal-retails--have-product' : ''
				}`}
			>
				<span className='f-modal-retails-icon' onClick={cerrar}>
					X
				</span>
				<div className='f-modal-retails-head'>
					ELIGE LA MEJOR OPCIÓN PARA TI
				</div>
				<div className='f-modal-retails-body'>
					{props.productInfo && (
						<div className='f-modal-retails-body__product'>
							<Img
								className='f-modal-retails-body__product__image'
								fluid={imgProducto.fluid}
								alt={imgProducto.description}
							/>
							<div className='f-modal-retails-body__product__description'>
								<h3 className='f-modal-retails-body__product__description__title'>
									{props.productInfo.nombreProducto}
								</h3>
								<div className='f-modal-retails-body__product__description__incontinencia'>
									{props.productInfo.textoModalRetails? <span className='f-retails-content-item-message'>{documentToReactComponents(props.productInfo.textoModalRetails.json, options)}</span>:''}
									{documentToReactComponents(
										props.productInfo.nivelIncontinencia?.json
									)}
								</div>
								<div className='f-modal-retails-body__product__description__gotas'>
									{renderDrops(
										props.productInfo.numeroDeGotas,
										props.productInfo.numeroDeGotasLlenas
									)}
								</div>
							</div>
						</div>
					)}
					{isSlider ? (
						<Slider {...settings} className='f-retails-content'>
							{listItems}
						</Slider>
					) : (
						<div
							className={`f-retails-content ${
								!props.productInfo &&
								retailByCountry?.retailsDeCompra?.length <= 4
									? 'f-retails-content--center'
									: ''
							}`}
						>
							{listItems}
						</div>
					)}
				</div>
			</div>
		</LayoutModal>
	)
}

export default ModalRetails
