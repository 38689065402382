export const convertText = (text) => {
    if (typeof text !== "string") { return text }

    const lowercaseText = text.toLowerCase()
    let firstCharacter = "";
    let finalText = "";

    if (lowercaseText.includes("¡")) {
        firstCharacter = lowercaseText.charAt(0) + lowercaseText.charAt(1).toUpperCase();
        finalText = firstCharacter + lowercaseText.slice(2);
    } else {
        firstCharacter = lowercaseText.charAt(0).toUpperCase();
        finalText = firstCharacter + lowercaseText.slice(1);
    }

    return finalText
    
}