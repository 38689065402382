import React, { useState, useEffect } from 'react'

import './modal-free-samples.scss'
import {addNoScroll, removeNoScroll} from '../../../state/utils/overflowHelper'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useLocaleMode from '../../../hooks/useLocaleMode'

const ModalFreeSamples = props => {
	const [scrollInitial, setScrollInitial] = useState(0)
	const { homeLink } = useHomeLinkMode()
	const { locale } = useLocaleMode()

	useEffect(() => {
		setScrollInitial(window.scrollY)
		addNoScroll()
		return () => {
			removeNoScroll()
		}
	}, [])

	const cerrar = () => {
		window.scroll({
			top: scrollInitial,
			left: 0,
			behavior: 'smooth',
		})
		props.hideAction()
	}

	return (
		<LayoutModal>
			<div className="f-modal-free-samples">
				<a href={ homeLink + 
					(locale === 'es-CO' ? 'muestra-gratis/' : 'pide-tu-muestra/') } 
					className='f-modal-free-samples-icon' onClick={cerrar}>
					X
				</a>
				{/* <div className='f-modal-free-samples-head'>
					ELIGE LA MEJOR OPCIÓN PARA TI
				</div> */}
				<div className='f-modal-free-samples-body'>
					<p><b>Tu muestra gratis será enviada a partir del 15 de enero de 2023.</b></p>
				</div>
			</div>
		</LayoutModal>
	)
}

export default ModalFreeSamples