import React from 'react'
import Img from 'gatsby-image'
import './modal-promos.scss'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import useMobileMode from '../../../hooks/useMobileMode'

const ModalPromos = props => {
	const { isMobile } = useMobileMode()
	return (
		<LayoutModal>
			<div className='f-modal-promos-wrapper'>
				<span className='f-modal-promos-close' onClick={props.closeAction}>
					X
				</span>
				<div className='f-modal-promos-body'>
					<a
						className='f-modal-promos-body-link'
						target='_blank'
						href={props.content.redireccionModalTransversal}
						rel='noreferrer noopener'
					>
						<Img
							className='f-img-promo'
							fluid={
								props.content.imagenesModalTransversal[isMobile ? 1 : 0].fluid
							}
							alt={props.content.imagenesModalTransversal.description}
						/>
					</a>
				</div>
			</div>
		</LayoutModal>
	)
}

export default ModalPromos
