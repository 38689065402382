import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import useBrowserMode from '../../../hooks/useBrowserMode'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useLocaleMode from '../../../hooks/useLocaleMode'
import useSessionState from '../../../hooks/useSessionState'
import { isAdviser } from '../../../state/utils/isAdviser'
import Logo from '../../molecules/logo/logo'
import NavMenu from '../../molecules/navMenu/navMenu'
import SearchBar from '../../molecules/searchBar/searchBar'
import SignInUser from '../../molecules/signInUser/signInUser'
import './header.scss'
import Icon from '../../atoms/Icon/Icon'
import SamplesBarHeader from '../../molecules/samples-bar-header/samples-bar-header'
import logoDescreet from './../../../assets/images/logo-discreet.svg'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { convertText } from '../../../utils/convertFirstCharacterToUppercase'

const Header = props => {
	const [isPopUpVisible, setPopUpVisibility] = React.useState(false)
	// eslint-disable-next-line no-unused-vars
	const [isLayoutVisible, setLayoutVisible] = React.useState(false)
	const [currentScroll, setCurrentScroll] = React.useState(0)
	const [isSamplesBarVisibility, setSamplesBarVisibility] = React.useState(true)
	const { homeLink } = useHomeLinkMode()
	const { isBrowser } = useBrowserMode()
	const { locale } = useLocaleMode()

	const adviserActive = () => {
		if (isBrowser) {
			if (
				localStorage.getItem('tokenData') ||
				sessionStorage.getItem('tokenData')
			) {
				if (sessionStorage.getItem('tokenData') !== null) {
					const tokenId = JSON.parse(sessionStorage.getItem('tokenData'))
						.idToken
					return isAdviser(tokenId)
				} else {
					const tokenId = JSON.parse(localStorage.getItem('tokenData')).idToken
					return isAdviser(tokenId)
				}
			} else {
				return false
			}
		} else {
			return false
		}
	}

	const user = props.currentUser

	const result = useStaticQuery(
		graphql`
			query CONTENTS {
				allContentfulTenaHome {
					nodes {
						node_locale
						tieneTiendas
						tieneMuestras
					}
				}
				allContentfulTenaMenu(filter: { titulo: { eq: "Menú > Megamenú" } }) {
					nodes {
						opcionesMenu {
							nombre
							slug
							titulo
						}
						node_locale
						botonComprar
						botonInicioDeSesion
						botonMuestrasGratis
						botonMuestrasGratisRedireccion
						botonRegistrate
						textoBarraSuperior
						textoBarraSuperiorMobile
					}
				}
			}
		`
	)

	const megaMenu =
		isBrowser &&
		result &&
		result.allContentfulTenaMenu.nodes.filter(
			node => node.node_locale === locale
		)[0]
	const countryHaveSamplesAndStores =
		isBrowser &&
		result &&
		result.allContentfulTenaHome.nodes.filter(
			node => node.node_locale === locale
		)[0]

	const { isLoggedUser } = useSessionState()

	const handleCloseSession = () => {
		localStorage.removeItem('tokenData')
		localStorage.removeItem('userName')
		localStorage.removeItem('userData')
		localStorage.removeItem('_grecaptcha')
		sessionStorage.clear()
		window.location.href = homeLink
	}

	const handleScroll = () => {
		const scrollTop = document.querySelector('html').scrollTop
		setCurrentScroll(scrollTop)
		if (currentScroll < scrollTop) {
			setSamplesBarVisibility(false)
			props.setIsSampleNav(false)
		} else {
			setSamplesBarVisibility(true)
			props.setIsSampleNav(true)
		}
	}
	const dataLayerVolver = () => {
		let location = ''
		if (typeof window !== 'undefined') {
			location = window.location.href
		}
		pushDataLayerEvent({
			event: 'authentication',
			category: 'login',
			action: 'click',
			label: 'Volver al inicio',
			location: location,
		})
	}

	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	})

	React.useEffect(() => {
		setSamplesBarVisibility(true)
	}, [])

	return (
		<>
			{!props.isFullPage || props.isFullPage === null ? (
				props.isMobile ? (
					<>
						<div className='f-header-wrapper'>
							<SamplesBarHeader
								countryHaveSamples={countryHaveSamplesAndStores.tieneMuestras}
								samplesBarVisibility={
									props.isDescreet ? false : isSamplesBarVisibility
								}
								barText={
									props.isMobile
										? megaMenu.textoBarraSuperiorMobile
										: megaMenu.textoBarraSuperior
								}
								samplesText={megaMenu.botonMuestrasGratis}
								samplesRedirection={megaMenu.botonMuestrasGratisRedireccion}
								homeLink={homeLink}
							/>
							<div
								className={
									'f-header' +
									(!isSamplesBarVisibility ? ' f-header--hidde' : '')
								}
							>
								<div className='f-main-header'>
									<Logo adviserActive={adviserActive} />
								</div>
							</div>
						</div>
						<header
							className={
								'f-header-wrapper f-header-wrapper--bottom' +
								(props.isDescreet ? ' f-descreet' : '')
							}
						>
							<div
								className={
									props.isChangePassword ? 'f-header noevents' : 'f-header'
								}
							>
								<div className='f-main-header'>
									<div className='f-nav-container'>
										<div className='f-nav-main-wrapper'>
											<a className='f-home-icon' href={homeLink}>
												<Icon
													icon='icon-home regal-blue'
													size='1'
													tagtype='span'
													// eslint-disable-next-line react/no-children-prop
													children={<label>Inicio</label>}
												/>
											</a>
											<SearchBar
												isMobile={props.isMobile}
												setPopUpVisibility={setPopUpVisibility}
											/>
											{countryHaveSamplesAndStores.tieneMuestras ? (
												<span
													className='f-samples-icon'
													onClick={() => {
														window.location.href =
															homeLink +
															(locale === 'es-CO'
																? 'muestra-gratis/'
																: 'pide-tu-muestra/')
													}}
												>
													Muestra gratis
												</span>
											) : null}
											<span
												className='f-buy-icon'
												onClick={() => {
													props.setModalVisibility(true)
												}}
											>
												{megaMenu.botonComprar}
											</span>
											<NavMenu
												currentPoints={props.currentPoints}
												handleBannerPaused={props.handleBannerPaused}
												isChangePassword={props.isChangePassword}
												currentUser={user}
												handleCloseSession={handleCloseSession}
												setLayoutVisible={setLayoutVisible}
												isMobile={props.isMobile}
												loggedUser={isLoggedUser}
												setPopUpVisibility={setPopUpVisibility}
												isPopUpVisible={isPopUpVisible}
												megaMenuCopies={{
													buyButton: megaMenu.botonComprar,
													sampleButton: megaMenu.botonMuestrasGratis,
													login: megaMenu.botonInicioDeSesion,
													register: megaMenu.botonRegistrate,
												}}
												setModalVisibility={props.setModalVisibility}
												setModalFreeSamples={props.setModalFreeSamples}
												locale={locale}
												isDescreet={props.isDescreet}
											/>
										</div>
									</div>
								</div>
							</div>
						</header>
					</>
				) : (
					<header
						className={
							'f-header-wrapper' + (props.isDescreet ? ' f-descreet' : '')
						}
					>
						<SamplesBarHeader
							countryHaveSamples={countryHaveSamplesAndStores.tieneMuestras}
							samplesBarVisibility={
								props.isDescreet ? false : isSamplesBarVisibility
							}
							barText={
								props.isMobile
									? megaMenu.textoBarraSuperiorMobile
									: megaMenu.textoBarraSuperior
							}
							samplesText={megaMenu.botonMuestrasGratis}
							samplesRedirection={megaMenu.botonMuestrasGratisRedireccion}
							homeLink={homeLink}
						/>
						<div
							className={
								props.isChangePassword ? 'f-header noevents' : 'f-header'
							}
						>
							<div className='f-main-header'>
								<Logo adviserActive={adviserActive} />
								<div className='f-nav-container'>
									{props.isMobile ? (
										<div className='f-nav-main-wrapper'>
											<span
												className='f-buy-icon'
												onClick={() => {
													props.setModalVisibility(true)
												}}
											>
												{megaMenu.botonComprar}
											</span>
											<SearchBar
												isMobile={props.isMobile}
												setPopUpVisibility={setPopUpVisibility}
											/>
											<NavMenu
												currentPoints={props.currentPoints}
												handleBannerPaused={props.handleBannerPaused}
												isChangePassword={props.isChangePassword}
												currentUser={user}
												handleCloseSession={handleCloseSession}
												setLayoutVisible={setLayoutVisible}
												isMobile={props.isMobile}
												loggedUser={isLoggedUser}
												setPopUpVisibility={setPopUpVisibility}
												isPopUpVisible={isPopUpVisible}
												megaMenuCopies={{
													buyButton: megaMenu.botonComprar,
													sampleButton: megaMenu.botonMuestrasGratis,
													login: megaMenu.botonInicioDeSesion,
													register: megaMenu.botonRegistrate,
												}}
												setModalVisibility={props.setModalVisibility}
												setModalFreeSamples={props.setModalFreeSamples}
												locale={locale}
											// countriesData={countriesData}
											/>
										</div>
									) : (
										<div className='f-nav-main-wrapper'>
											{props.isDescreet && (
												<a
													href={homeLink + 'discreet/'}
													className='f-logo-descreet'
												>
													<img src={logoDescreet} alt='logo descreet' />
												</a>
											)}

											{!adviserActive() && (
												<SearchBar isMobile={props.isMobile} />
											)}
											{!adviserActive() && (
												<SignInUser
													currentPoints={props.currentPoints}
													handleBannerPaused={props.handleBannerPaused}
													isChangePassword={props.isChangePassword}
													currentUser={user}
													handleCloseSession={handleCloseSession}
													isMobile={props.isMobile}
													loggedUser={isLoggedUser}
													setPopUpVisibility={setPopUpVisibility}
													isPopUpVisible={isPopUpVisible}
													megaMenuCopies={{
														login: megaMenu.botonInicioDeSesion,
														register: megaMenu.botonRegistrate,
													}}
												/>
											)}
										</div>
									)}
								</div>
							</div>
							{!props.isMobile && !adviserActive() && (
								<NavMenu
									megaMenuCopies={{
										buyButton: megaMenu.botonComprar,
										sampleButton: megaMenu.botonMuestrasGratis,
									}}
									setModalVisibility={props.setModalVisibility}
									setModalFreeSamples={props.setModalFreeSamples}
									locale={locale}
									isDescreet={props.isDescreet}
								// countriesData={countriesData}
								/>
							)}
						</div>
					</header>
				)
			) : (
				<header className='f-header-wrapper--fullpage'>
					<div className='f-header-container'>
						<a
							href={
								props.btnReturnRedirection === '/'
									? homeLink
									: homeLink + props.btnReturnRedirection
							}
							className='f-header-logo'
						>
							<Logo adviserActive={adviserActive} />
						</a>
						<div className='f-header-wrapper-fullpage'>
							<div
								className={`f-button-back ${props.btnReturnWhite ? 'white' : ''
									}`}
							>
								<div
									className='f-btn-return'
									onClick={() => {
										if (props.isIndex) {
											dataLayerVolver()
										}
										localStorage.setItem('back', '')
										window.location.href =
											props.btnReturnRedirection === '/'
												? homeLink
												: homeLink + props.btnReturnRedirection
									}}
								>
									<Icon icon='icon-arrow-left white' size='3' tagtype='span' />
								</div>
								<p>{convertText(props.btnReturn)}</p>
							</div>

							<SignInUser
								isFullPage={props.isFullPage}
								isMobile={props.isMobile}
								haveFontEvents={props.haveFontEvents}
							/>
						</div>
					</div>
				</header>
			)}
		</>
	)
}

export default Header
