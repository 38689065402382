import React from 'react'
import './layout-modal.scss'

const LayoutModal = props => {
	return (
		<section className='f-modal-container'>
			<div className='f-modal-facade' onClick={props.onClose} />
			{props.children}
		</section>
	)
}

export default LayoutModal
