import React, { useState } from 'react'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import WhatsApp from '../../../assets/images/whatsapp-icon.png'
import Box1 from '../../../assets/images/modal-whatsapp/box_1.png'
import Box2 from '../../../assets/images/modal-whatsapp/box_2.png'
import Box3 from '../../../assets/images/modal-whatsapp/box_3.png'
import { useStaticQuery, graphql } from 'gatsby'
import useLocaleMode from '../../../hooks/useLocaleMode'
import useMobileMode from '../../../hooks/useMobileMode'
import DataSection from './modal-whatsapp-data.json'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import useBrowserMode from '../../../hooks/useBrowserMode'
import './modal-whatsapp.scss'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import { siteUrl } from '../../../state/utils/site-url'

const ModalWhatsapp = ({ hiddeAction }) => {
	const { locale } = useLocaleMode()
	const query = useStaticQuery(graphql`
		query WHATSAPP_INFO {
			allContentfulTenaHome {
				nodes {
					numeroDeWhatsApp
					node_locale
					tieneChat
					imagenChatWhatsApp {
						file {
							url
						}
					}
				}
			}
		}
	`)

	const { isMobile } = useMobileMode()
	const { isBrowser } = useBrowserMode();
	const { homeLink } = useHomeLinkMode()

	const whatsappInfo =
		query &&
		query.allContentfulTenaHome.nodes.filter(node =>
			node.node_locale.includes(locale)
		)[0]
	const DataSectionByCountry = DataSection[locale] || DataSection['es-CO'] || {}
	const [stepModal, setStepModal] = useState(1)

	React.useEffect(() => {
		return () => {
			setStepModal(0)
		}
	}, [])

	React.useEffect(() => {
		if (isMobile) {
			window.open(
				'https://api.whatsapp.com/send?phone=' + whatsappInfo.numeroDeWhatsApp
			)
			hiddeAction()
		}
	})
    const userInfo=dataLayerUserInfo()
    const dataLayerWhatsapp =()=>{
        let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
        pushDataLayerEvent({event: 'Interacciones - Asesoría',
		category: 'chat',
		action: 'click',
		label: 'WhatsApp',
		location: loc,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		pushDataLayerEvent({
			event: 'chat',
			action_type: 'Send message'
		})
	}

	const validateFromRecoveryPassword = () => {
		let location= isBrowser  && window.location.href;

		if(location.at(-1)  === homeLink + "autenticacion/recuperar-contrasena/#") {
			localStorage.setItem("fromRecoveryPassword", true)
		}

		if(location === homeLink + "autenticacion/recuperar-contrasena/#"){ 
			window.location.href= homeLink + "autenticacion/recuperar-contrasena/"
		}
	}

	const IconClose = (
		<i
			className='far fa-times-circle f-modal-whatsapp__step__close-icon'
			onClick={() => {
				hiddeAction();
				validateFromRecoveryPassword()
				if (localStorage.getItem("dataUserRegistered")) {
					window.location.href = siteUrl + "/registro/"
				}
				if (localStorage.getItem("dataUserRegisteredProspect")) {
					window.location.href = siteUrl + "/academia-tena/"
				}
				if (localStorage.getItem("dataUserRegisteredMigrated")) {
					window.location.href = siteUrl + "/autenticacion/"
				}
			}}
		></i>
	)
	const IconWhatsApp = (
		<a
			href={
				'https://api.whatsapp.com/send?phone=' + whatsappInfo.numeroDeWhatsApp
			}
			target='_blank'
			className='f-modal-whatsapp__step__button'
			rel='noreferrer'
			onClick={dataLayerWhatsapp}
		>
			<img src={WhatsApp} alt='icono whatsapp' /> Ir a WhatsApp
		</a>
	)

	return (
		<LayoutModal onClose={hiddeAction}>
			<div className='f-modal-whatsapp'>
				<div
					className={
						'f-modal-whatsapp__step f-modal-whatsapp__step1' +
						(stepModal === 1 ? ' f-modal-whatsapp__step--show' : '')
					}
				>
					{IconClose}
					<figure className='f-modal-whatsapp__step1__icon'>
						<img src={WhatsApp} alt='icono whatsapp' />
					</figure>
					<h3 className='f-modal-whatsapp__step__title'>
						{DataSectionByCountry?.title}
					</h3>
					<p className='f-modal-whatsapp__step__text'>
						{DataSectionByCountry?.description}
					</p>
					<p
						onClick={() => setStepModal(2)}
						className='f-modal-whatsapp__step__text f-modal-whatsapp__step1__next-container'
					>
						{DataSectionByCountry.subtitle}
						<i className='fal fa-chevron-right'></i>
					</p>
					{IconWhatsApp}
				</div>
				<div
					className={
						'f-modal-whatsapp__step f-modal-whatsapp__step2' +
						(stepModal === 2 ? ' f-modal-whatsapp__step--show' : '')
					}
				>
					{IconClose}

					<p className='f-modal-whatsapp__step__text f-modal-whatsapp__step2__title'>
						{DataSectionByCountry.subtitle}
					</p>

					<div className='f-modal-whatsapp__step2__images'>
						<img src={Box1} alt='paso 1' />
						<img src={Box2} alt='paso 2' />
						<img src={Box3} alt='paso 3' />
					</div>

					<p className='f-modal-whatsapp__step__text'>
						{DataSectionByCountry?.successDescription}
					</p>

					{IconWhatsApp}
				</div>
			</div>
		</LayoutModal>
	)
}

export default ModalWhatsapp
