import React from 'react'
import PropTypes from 'prop-types'
import './Icon.scss'

const Icon = props =>
	props.tagtype === 'span' ? (
		<span
			onClick={props.onclick}
			className={'f-icon ' + props.icon + ' x' + props.size}
		>
			{props.children}
		</span>
	) : (
		<i className={'f-icon f-icon--i ' + props.icon + ' x' + props.size}></i>
	)

Icon.propTypes = {
	icon: PropTypes.string,
	size: PropTypes.string,
	tagtype: PropTypes.string,
	onclick: PropTypes.func,
	children: PropTypes.node,
}
export default Icon
