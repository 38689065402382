import PropTypes from 'prop-types'
import React from 'react'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import { convertText } from '../../../utils/convertFirstCharacterToUppercase'
import './Button.scss'


const Button = props => {

	const userInf = dataLayerUserInfo();
	const dataLayerDatosP = (lab) => {
		let loc = ''
		if (typeof window !== 'undefined') {
			loc = window.location.href
		}
		pushDataLayerEvent({
			event: 'Interacciones - Mi Cuenta',
			category: 'datos personales',
			action: 'click',
			label: lab,
			location: loc,
			user_data: userInf.user_data ? userInf.user_data : '',
			log_status: userInf.log_status,
		})
	}
	return props.type === 'button' ? (
		<button
			id={props.id}
			type='button'
			className={props.classname}
			disabled={props.isEnabled}
			onClick={props.onClickAction}
		>
			{convertText(props.text)}
		</button>
	) : (
		<input
			className={props.classname}
			type='submit'
			value={convertText(props.text)}
			disabled={props.isEnabled}
			onClick={() => {
				if (props.text === 'ACTUALIZAR DATOS') {
					dataLayerDatosP('Actializar datos')
				}
			}}
		/>
	)
}
Button.propTypes = {
	type: PropTypes.string.isRequired,
	classname: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	onClickAction: PropTypes.func,
	isEnabled: PropTypes.bool,
}

export default Button
