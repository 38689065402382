import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import useBrowserMode from '../../../hooks/useBrowserMode'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useLocaleMode from '../../../hooks/useLocaleMode'
import {
	addNoScroll,
	removeNoScroll,
} from '../../../state/utils/overflowHelper'
import './navMenu.scss'
import SignInUser from '../signInUser/signInUser'
import * as constants from '../../../state/constants/appConstants'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import { isPyAnduY } from '../../../utils/verifyPyAndUy'
const NavMenu = props => {
	const node = useRef()
	const [listOpen, setCountriesState] = useState(false)
	// eslint-disable-next-line no-unused-vars
	const [fCloseClass, setClass] = useState('')
	const [isMenuActive, setMenuActive] = useState(false)
	const [navActive, setNavActive] = useState(null)
	const [navIdActive, setNavIdActive] = useState(null)
	const { homeLink } = useHomeLinkMode()
	const { isBrowser } = useBrowserMode()
	const { locale } = useLocaleMode()

	// FONT
	const html = isBrowser && document.getElementsByTagName('html')[0]
	const [currentFontSize, setCurrentFontsize] = useState(constants.MIN_FONT)

	const handleChangeSize = _newFontSize => {
		setCurrentFontsize(_newFontSize)
		html.style.fontSize = `${_newFontSize}px`
		localStorage.setItem('fontSize', _newFontSize)
	}

	useEffect(() => {
		const storageFontSize = localStorage.getItem('fontSize')
		if (storageFontSize) {
			setCurrentFontsize(Number(storageFontSize))
			html.style.fontSize = `${Number(storageFontSize)}px`
		}
	}, [])

	useEffect(() => {
		if (!navActive) {
			const url = window.location.pathname
			const urlSplit = url.split('/')
			if (urlSplit.length > 1) {
				setNavActive(urlSplit[1])
			}
		}
	}, [])

	const query = useStaticQuery(graphql`
		query LOGO_SALE_MENU {
			allContentfulTenaHome {
				nodes {
					node_locale
					tieneTiendas
					tieneMuestras

					retailsDeCompra {
						redireccionRetail
					}
				}
			}
			allContentfulTenaMenu(filter: { titulo: { eq: "Menú > Megamenú" } }) {
				nodes {
					opcionesMenu {
						nombre
						slug
						titulo
						submenu {
							nombre
							slug
							titulo
							submenu {
								nombre
								slug
								titulo
							}
						}
					}
					node_locale
				}
			}
		}
	`)


const homeQuery =
isBrowser &&
query &&
query.allContentfulTenaHome.nodes.filter(
	node => node.node_locale === locale
	)[0]
	const navMenuOptions =
		isBrowser &&
		query &&
		query.allContentfulTenaMenu.nodes.filter(
			node => node.node_locale === locale
		)[0]

	const workWithUs = navMenuOptions.opcionesMenu?.find(option => option.nombre === 'CONTÁCTANOS') || null;
	const workWithUsIndex = navMenuOptions.opcionesMenu?.findIndex(option => option.nombre === 'CONTÁCTANOS')|| null;
	const normalizeOption = optionName => optionName?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

	const toggleMenu = isMenuActive => {
		if (isMenuActive) {
			setTimeout(() => {
				setMenuActive(false)
			})
			removeNoScroll()
		} else {
			setMenuActive(true)
			addNoScroll()
		}
	}

	const handleClickOutside = e => {
		if (node.current.contains(e.target)) {
			return
		}
		setClass('f-closed-dropdown')
		setTimeout(() => {
			setCountriesState(false)
			setClass('')
		}, 500)
	}

	const handleClickMenuOption = (event, id) => {
		event.preventDefault()
		toggleMenu(true)
		setNavIdActive(id)
		const elementSearched = document.getElementById(id)
		window.scroll({
			top: elementSearched
				? elementSearched.offsetTop - (props.isMobile ? 50 : 200)
				: 0,
			left: 0,
			behavior: 'smooth',
		})
	}


	const validateIsWhatsApp = (e, slug) => {
		if (slug.toLowerCase().includes('whatsapp')) {
			e.preventDefault()
			window.location.hash = 'asesoria-whatsapp'
		}
	}
	const obtenerCategoria = (option,separador) =>{
		const categoria= option.split(separador)
		return categoria[0]
	}

	const renderDesplegable = ({ submenu }, indexMenu) => {
		return (
			<div className='desplegable'>
				{submenu?.map((subOption, index) =>
					subOption.submenu ? (
						<div className='f-second-level-item'>
							{subOption.nombre}
							<ul className='f-second-level-dropdown'>
								{subOption.submenu.map(({ nombre, slug, titulo }, indax) => (
									<li className='f-dropdown-item' key={slug}>
										<a
											className='f-dropdown-item__link'
											rel='noopener noreferrer'
											target={slug.includes('https:') ? '_blank' : '_self'}
											href={
												slug.includes('tel:') || slug.includes('https:')
													? slug
													: homeLink + slug
											}
											onClick={e => {
												dataLaterMenu(obtenerCategoria(titulo,'>'), nombre)
												dataLayerMenuClick('Productos', titulo, `${indexMenu}.${index+1}.${indax+1}`)
												validateIsWhatsApp(e, slug)
											}}
										>
											{nombre}
										</a>
									</li>
								))}
							</ul>
						</div>
					) : (
						<a
							key={index}
							rel='noopener noreferrer'
							target={subOption.slug.includes('https:') ? '_blank' : '_self'}
							href={
								subOption.slug.includes('tel:') ||
								subOption.slug.includes('https:')
									? subOption.slug
									: homeLink + subOption.slug
							}
							onClick={e => {
								dataLaterMenu(obtenerCategoria(subOption.titulo,'>'), subOption.nombre)
								dataLayerMenuClick(obtenerCategoria(subOption.titulo,'>'), subOption.nombre, `${indexMenu}.${index+1}`)
								validateIsWhatsApp(e, subOption.slug)
							}}
						>
							{subOption.nombre}
						</a>
					)
				)}
			</div>
		)
	}

	useEffect(() => {
		const id = window.location.hash?.replace('#', '')
		setNavIdActive(id)
	}, [])

	useEffect(() => {
		if (listOpen) {
			document.addEventListener('mousedown', handleClickOutside)
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			removeNoScroll()
		}
	}, [listOpen])

	const userInfo=dataLayerUserInfo()
	
	const dataLaterMenu = (option, subCat) => {
		const category=option
		const sub= subCat!==''? subCat: 'ninguno'
		const label = sub === 'ninguno'? option: subCat
		let location=''
		if (typeof window !== 'undefined') {
			location= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Menú',
		category,
		action: 'click',
		label,
		sub_category: sub,
		location, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}
	const indexMenu= navMenuOptions.opcionesMenu?.length || 0
	const dataLayerMenuClick = (option, subCat,indexMen, menuName='Mega menu') => {
		const opt= subCat!==''? `${option} > ${subCat}`: option
		pushDataLayerEvent({event: 'menu_click',
		action_type: 'Link',
		link_text: opt,
		index:indexMen,
  		menu_name: menuName,})
	}
	
	return (
		<>
			<div className='f-menu-button'>
				<button
					id='f-header-burger-menu'
					className={'f-header-burguer'}
					type='button'
					onClick={() => {
						toggleMenu(false)
					}}
				>
					<span className={'f-header-burguer__line'}></span>
					<span className={'f-header-burguer__line'}></span>
					<span className={'f-header-burguer__line'}></span>
				</button>
				<p className={`${'f-menu-button-copy f-menu-button-copy--blue'}`}>
					{'Menú'}
				</p>
			</div>
			<div
				className={
					isMenuActive
						? 'f-nav-menu-container f-nav-menu-container--active'
						: 'f-nav-menu-container'
				}
			>
				<div className='f-nav-menu-container-header' />
				<div className='f-nav-menu-container-body'>
					{isMenuActive && (
						<div className='f-menu-title-container'>
							<span className='f-menu-title'>MENÚ</span>
							<div className='f-user-main-container f-user-main-container--fullpage events'>
								<div className='f-btn-container'>
									<button
										onClick={() => {
											handleChangeSize(currentFontSize - 2)
										}}
										className={`${
											currentFontSize <= constants.MIN_FONT
												? 'f-btn-decrease f-btn-disabled'
												: 'f-btn-decrease'
										}`}
									>
										A-
									</button>
									<button
										onClick={() => {
											handleChangeSize(currentFontSize + 2)
										}}
										className={`${
											currentFontSize >= constants.MAX_FONT
												? 'f-btn-increase f-btn-disabled'
												: 'f-btn-increase'
										}`}
									>
										A+
									</button>
								</div>
							</div>
						</div>
					)}
					{isMenuActive && (
						<SignInUser
							currentPoints={props.currentPoints}
							handleBannerPaused={props.handleBannerPaused}
							isChangePassword={props.isChangePassword}
							currentUser={props.currentUser}
							handleCloseSession={props.handleCloseSession}
							isMobile={props.isMobile}
							loggedUser={props.loggedUser}
							setPopUpVisibility={props.setPopUpVisibility}
							isPopUpVisible={props.isPopUpVisible}
							isMenuActive={isMenuActive}
							megaMenuCopies={{
								login: props.megaMenuCopies.login,
								register: props.megaMenuCopies.register,
							}}
						/>
					)}
					{isBrowser && (
						<>
							<ul>
								{props.isDescreet === true ? (
									<>
										<li>
											<a
												href='#productos'
												className={
													'f-nav-link' +
													(navIdActive === 'productos' ? ' nav-active' : '')
												}
												onClick={e => handleClickMenuOption(e, 'productos')}
											>
												PRODUCTOS
											</a>
										</li>
										<li>
											<a
												href='#bienvenido'
												className={
													'f-nav-link' +
													(navIdActive === 'bienvenido' ? ' nav-active' : '')
												}
												onClick={e => handleClickMenuOption(e, 'bienvenido')}
											>
												BIENVENIDOS LOS CAMBIOS
											</a>
										</li>
										<li>
											<a
												href='#preguntasFrecuentes'
												className={
													'f-nav-link' +
													(navIdActive === 'preguntasFrecuentes'
														? ' nav-active'
														: '')
												}
												onClick={e =>
													handleClickMenuOption(e, 'preguntasFrecuentes')
												}
											>
												PREGUNTAS FRECUENTES
											</a>
										</li>
									</>
								) : (
									navMenuOptions.opcionesMenu.map((option, index) => {
										const optionName = option.nombre?.toLowerCase()
										const optionNormalized = normalizeOption(optionName)
										const submenu = option.submenu

										if (submenu) {
											return (
												<li key={index} className='contenedor-desplegable'>
													<div
														className={
															'f-nav-link ' +
															(navActive === optionNormalized
																? 'nav-active'
																: '')
														}
													>
														{option.nombre}

														{renderDesplegable({ submenu }, index+1)}
													</div>
												</li>
											)
										} else {
											return (
												option.nombre !== "CONTÁCTANOS" ?
												<li key={index}>
													<a
														rel='noopener noreferrer'
														target={
															option.slug.includes('https:')
																? '_blank'
																: '_self'
														}
														className={
															'f-nav-link ' +
															(navActive === optionNormalized
																? 'nav-active'
																: '')
														}
														onClick={e => {
															dataLaterMenu(option.nombre,'')
															dataLayerMenuClick(option.nombre,'', index+1)
															validateIsWhatsApp(e, option.slug)
														}}
														href={
															option.slug.includes('tel:') ||
															option.slug.includes('https:')
																? option.slug
																: homeLink + option.slug
														}
													>
														{option.nombre}
													</a>
												</li>
												:null
											)
										}
									})
								)}
							</ul>
							<div className='f-bottom-wrapper'>
								<>
									{!window.location.pathname.includes('recomendador') &&
										homeQuery.tieneTiendas && !isPyAnduY? (
										<button
											className='f-nav-link-buttons f-nav-link-buttons--primary'
											onClick={() => {
												dataLaterMenu('Comprar', '')
												dataLayerMenuClick('Comprar', '', indexMenu)
												if (process.env.LOCALE === "es-CO") {
													window.location.href = homeQuery.retailsDeCompra[0]
														.redireccionRetail
														? homeQuery.retailsDeCompra[0].redireccionRetail
														: "https://tienda.tena.com.co/"
												} else if (process.env.LOCALE === "es-PE") {
													window.location.href = "https://tienda.tena.com.pe/"
												}else {
													props.setModalVisibility(true)
												}

											}}
										>
											COMPRAR
										</button>
									) : null}
									{homeQuery.tieneMuestras && !isPyAnduY ? (
										<a
											className='f-nav-link-buttons f-nav-link-buttons--secondary'
											href={
												homeLink +
												(locale === 'es-CO'
													? 'muestra-gratis/'
													: 'pide-tu-muestra/')
											} 
											onClick={() => {
												props.setModalFreeSamples(false);
												dataLaterMenu('Muestra Gratis','');
												dataLayerMenuClick('Muestra Gratis','', indexMenu+1)
											}}
										>
											{props.megaMenuCopies.sampleButton}
										</a>
									) : null}
								</>
							</div>
							{
								workWithUs && (
									<ul>
										<li>
											<a
												rel='noopener noreferrer'
												target={
													workWithUs.slug.includes('https:')
														? '_blank'
														: '_self'
												}
												className={
													'f-nav-link--left ' +
													(navActive === normalizeOption('contacto')
														? 'nav-active'
														: '')
												}
												onClick={e => {
													dataLaterMenu(workWithUs.nombre, '')
													dataLayerMenuClick(workWithUs.nombre, '', workWithUsIndex + 1)
													validateIsWhatsApp(e, workWithUs.slug)
												}}
												href={
													workWithUs.slug.includes('tel:') ||
														workWithUs.slug.includes('https:')
														? workWithUs.slug
														: homeLink + workWithUs.slug
												}
											>
												{workWithUs.nombre}
											</a>
										</li>
									</ul>
								)
							}
						</>
					)}
				</div>
				<div className='f-nav-menu-container-footer'>
					<SignInUser
						isMobile={false}
						isFullPage={true}
						toggleMenu={toggleMenu}
					/>
				</div>
			</div>
		</>
	)
}
export default NavMenu
