const jwtDecode = require('jwt-decode')

export const isAdviser = idToken => {
	return (
		typeof jwtDecode(idToken)['cognito:groups'] !== 'undefined' &&
		jwtDecode(idToken)['cognito:groups'].includes('Asesores')
	)
}

export const isQualify = idToken => {
	return (
		typeof jwtDecode(idToken)['custom:role'] !== 'undefined' &&
		jwtDecode(idToken)['custom:role'].includes('CALIDAD')
	)
}

export const isKonecta = idToken => {
	return (
		typeof jwtDecode(idToken)['custom:role'] !== 'undefined' &&
		jwtDecode(idToken)['custom:role'].includes('KONECTA')
	)
}

export const isTrack = idToken => {
	return (
		typeof jwtDecode(idToken)['custom:role'] !== 'undefined' &&
		jwtDecode(idToken)['custom:role'].includes('ASESOR_TRACK')
	)
}

export const isAdmin = idToken => {
	return (
		typeof jwtDecode(idToken)['custom:role'] !== 'undefined' &&
		jwtDecode(idToken)['custom:role'].includes('ADMIN')
	)
}
export const isTenaAdviser = idToken => {
	return (
		typeof jwtDecode(idToken)['custom:role'] !== 'undefined' &&
		jwtDecode(idToken)['custom:role'].includes('ASESOR_TENA')
	)
}
