import React, { useState } from 'react'
import './point.scss'

const Point = ({ name, description, user }) => {
	const [active, setActive] = useState(false)

	return (
		<div
			className={user ? 'f-mark-point f-mark-point-user' : 'f-mark-point'}
			onClick={() => setActive(!active)}
		>
			{active && (
				<div className='f-mark-point-detail'>
					<h2>{name}</h2>
					<span>{description}</span>
				</div>
			)}
		</div>
	)
}

export default Point
