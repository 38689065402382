//  Cloudfront
export const SERVER_URL =
	process.env.ENVIRONMENT === 'STG'
		? 'https://l60xo7vpwj.execute-api.us-east-1.amazonaws.com/stg/tena/'
		: process.env.ENVIRONMENT === 'PDN'
		? 'https://2blh4fqkq6.execute-api.us-east-1.amazonaws.com/pdn/tena/'
		: 'https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev/tena/'

// Refresh Token
export const REFRESH_TOKEN =
	process.env.ENVIRONMENT === 'PDN'
		? 'https://tena-pdn.auth.us-east-1.amazoncognito.com/oauth2/token'
		: 'https://tena-dev.auth.us-east-1.amazoncognito.com/oauth2/token'
// Client Id
export const CLIENT_ID =
	process.env.ENVIRONMENT === 'PDN'
		? '57a31p9ich1na2lcjjcpagoalc'
		: '5nasqgvvppce004pjtojsfhuea'

// Register
// export const REGISTER_USER_PATH = SERVER_URL + "register/api/v1/user"; commented for old register
export const REGISTER_USER_PATH = SERVER_URL + 'register/api/v1/user'
export const REGISTER_RESEND_CODE =
	SERVER_URL + 'register/api/v1/user/resend-code'
export const CONFIRMATION_REGISTER =
	SERVER_URL + 'register/api/v1/user/confirmation'
export const CONFIRMATION_URL =
	process.env.ENVIRONMENT === 'PDN'
		? 'https://pit34dq5ak.execute-api.us-east-1.amazonaws.com/pdn/url/'
		: 'https://900syq8ccc.execute-api.us-east-1.amazonaws.com/dev/url/'
export const REGISTER_PATH = SERVER_URL + 'register/api/v1/user'
export const REGISTER_EMAIL = SERVER_URL + 'register/api/v1/email'
export const REGISTER_CELLPHONE = SERVER_URL + 'register/api/v1/cell-phone'
export const REGISTER_DOCUMENT = SERVER_URL + 'register/api/v1/number-id'
export const REGISTER_ACCOUNT_INSTITUTION_ID =
	SERVER_URL + 'register/api/v1/number-id'
export const GER_MIGRATED_INFO = SERVER_URL + 'authentication/api/v1/migration/'
export const RECOVERY_NO_OTP = SERVER_URL + 'recovery/api/v1/change-recovery'
export const VALIDATE_EMAIL_CELL =
	SERVER_URL + 'register/api/v1/email-and-cellphone'
export const UPDATE_USER_WITHOUT_TOKEN =
	SERVER_URL + 'account/api/v1/user-without-token'

// Autentication
export const AUTH_EMAIL = SERVER_URL + 'authentication/api/v1/email'
export const AUTH_LOGIN = SERVER_URL + 'authentication/api/v1/auth'
export const RECOVERY_PASSWORD_AUTH =
	SERVER_URL + 'authentication/api/v1/change-password'
export const CRM_LOGIN = SERVER_URL + 'authentication/api/v1/crm/auth'

// Recovery
export const RECOVERY_EMAIL = SERVER_URL + 'recovery/api/v1/email'
export const RECOVERY_PASSWORD = SERVER_URL + 'recovery/api/v1/password/'
export const CHANGE_PASSWORD = SERVER_URL + 'recovery/api/v1/change-password'
export const UPDATE_PROFILE = SERVER_URL + 'account/api/v1/user'
export const VALIDATE_CHANGE_EMAIL = SERVER_URL + 'account/api/v1/emailChange'
export const GET_USER_INFO_BY_DOCUMENT =
	SERVER_URL + 'recovery/api/v1/number-id'

// Account
export const ACCOUNT_POINTS =
	SERVER_URL + 'accumulation/api/v1/accumulation-detail'
export const ACCOUNT_TOTAL_POINTS = SERVER_URL + 'account/api/v1/user/'
export const ACCOUNT_REDEMPTIONS = SERVER_URL + 'redemption/api/v1/redemptions'
export const ACCOUNT_CHANGE_EMAIL = SERVER_URL + 'account/api/v1/emailChange'
export const ACCOUNT_CHANGE_EMAIL_CONFIRM =
	SERVER_URL + 'account/api/v1/emailChange/confirm'
export const ACCOUNT_CANCEL_CHANGE_EMAIL =
	SERVER_URL + 'account/api/v1/emailChange'
export const ACCOUNT_VALIDATE_EMAIL =
	SERVER_URL + 'account/api/v1/validate/email'
export const ACTIVE_ACCOUNT = SERVER_URL + 'account/api/v1/update-state-active'
export const UPDATE_HABEAS = SERVER_URL + 'account/api/v1/data-policy'

// OTP
export const ACCOUNT_OTP_KEY = SERVER_URL + 'otp/api/v1/get-key'
export const ACCOUNT_CHANGE_EMAIL_OTP = SERVER_URL + 'otp/api/v1/otp/email'

// Samples
export const SHOW_SAMPLES_SENT = SERVER_URL + 'samples/api/v1/user/samples'
export const SEND_SAMPLES = SERVER_URL + 'samples/api/v1/sample-request'
export const SEND_SAMPLES_TEMPORAL =
	SERVER_URL + 'samples/api/v1/sample/without-registry'
export const GET_MY_ORDERS = SERVER_URL + 'samples/api/v1/user/orders'
export const GET_FOR_ID_ORDERS =
	SERVER_URL + 'samples/api/v1/user/orders/detail'
export const DELETE_USERS_CART_FORGOTTEN =
	SERVER_URL + 'samples/api/v1/sample-intent'
export const ADD_USERS_CART_FORGOTTEN =
	SERVER_URL + 'samples/api/v1/sample-intent'
export const VERIFY_COVERAGE = SERVER_URL + 'samples/api/v1/verify-coverage'

// Benefits
export const BENEFITS_ACCUMULATE = SERVER_URL + 'accumulation/api/v1/key'
export const BENEFITS_VOUCHER_REWARD = SERVER_URL + 'redemption/api/v1/reward'

// Transversal
export const CONTACTUS = SERVER_URL + 'pqrs/api/v1/contact'

// Products
export const LIKES_PRODUCTS = SERVER_URL + 'rating/api/v1/producto'
export const COMMENTS_PRODUCT = SERVER_URL + 'rating/api/v1/answers/product'
export const QUALIFY_PRODUCT = SERVER_URL + 'rating/api/v1/answers/product'
export const AVERAGE_PRODUCTS = SERVER_URL + 'rating/api/v1/products/average'

// Articles
export const LIKES_ARTICLES = SERVER_URL + 'rating/api/v1/articulo'
export const LEAFLET_VALIDATE =
	SERVER_URL + 'register/api/v1/email-and-cellphone'
export const LEAFLET_REGISTER = SERVER_URL + 'register/api/v1/user/prospect'
// Benefits
export const LIKES_BENEFITS = SERVER_URL + 'rating/api/v1/bono'

// Likes
export const SET_LIKE = SERVER_URL + 'rating/api/v1/like'
export const SET_UNLIKE = SERVER_URL + 'rating/api/v1/unlike'

// Poll
export const POLL_ANSWERS = SERVER_URL + 'poll/api/v1/answer'

// Get ip Address
export const GET_IP_ADDRESS = 'https://jsonip.com/'

// CallCenter
export const OUTGOING_CALLS = SERVER_URL + 'outgoing-call/api/v1/outgoing-calls'
export const HISTORY_USER = SERVER_URL + 'history/api/v1/user'
export const HISTORY_USER_DETAIL = SERVER_URL + 'history/api/v1/detail-pqr'
export const OUTGOING_CALL_STATES =
	SERVER_URL + 'outgoing-call/api/v1/outgoing-calls'
export const PQRS_LIST = SERVER_URL + 'pqrs/api/v1/pqrs'
export const PQRS_SOLUTION = SERVER_URL + 'pqrs/api/v1/pqrs'
export const PQRS_EXCEL = SERVER_URL + 'pqrs/api/v1/pqrs/download'
export const LEAFLET_REGISTER_CALLCENTER =
	SERVER_URL + 'register/api/v1/call-center/user/prospect'

// Qualification
export const QUALIFICATION = SERVER_URL + 'rating/api/v1/answers/save'

// Webinar
export const WEBINAR_PROSPECT = SERVER_URL + 'register/api/v1/user/prospect'
export const WEBINAR_OTP = SERVER_URL + 'otp/api/v1/prospect/key'
export const WEBINAR_OTP_VALIDATE = SERVER_URL + 'register/api/v1/user'
export const WEBINAR_CERTIFICATE =
	SERVER_URL + 'events/api/v1/certificate/download'
export const WEBINAR_EMAIL_VALIDATE = SERVER_URL + 'events/api/v1/enter'

//  EVENT
export const EVENT_READ = SERVER_URL + 'events/api/v1/content/read'
export const GET_EVENTS = SERVER_URL + 'events/api/v1/participation/event'
export const GET_EVENTS_LANDING = SERVER_URL + 'events/api/v1/event-participation-detail'
export const LIKE_EVENT = SERVER_URL + 'events/api/v1/participation/like'
export const GET_PRESIGNED_URL = SERVER_URL + 'events/api/v1/get-presigned-url'
export const CREATE_PARTICIPATION_EVENT =
	SERVER_URL + 'events/api/v1/participation/event'
export const CREATE_PARTICIPATION_EVENT_LANDING =
	SERVER_URL + 'events/api/v1/create-participation-detail'
export const GET_PARTICIPATION_EVENT =
	SERVER_URL + 'events/api/v1/participation'
export const GET_PARTICIPATION_EVENT_LANDING =
	SERVER_URL + 'events/api/v1/participation-detail'
export const GET_PARTICIPATION_EVENT_WITH_CODE =
	SERVER_URL + 'events/api/v1/competition-participation-information'

// Consulting
export const CONSULTING_SAVE = SERVER_URL + 'consulting/api/v1/consulting'
export const CONSULTING_LIKE = SERVER_URL + 'consulting/api/v1/consultation'
//  Callcenter consulting
export const GET_CONSULTING =
	SERVER_URL + 'consulting/api/v1/call-center/consulting'

export const ANSWER_CONSULTING =
	SERVER_URL + 'consulting/api/v1/call-center/close-consultation'
export const GET_HISTORY_CONSULTING =
	SERVER_URL + 'consulting/api/v1/call-center/consultation/history'

export const GET_LAST_FIVE_CAMPAIGNS = SERVER_URL + 'solutions/api/v1/campaigns'
export const UPLOAD_CAMPAIGN_FILE = SERVER_URL + 'solutions/api/v1/campaign'
export const GET_ACTIVE_USERS =
	SERVER_URL + 'solutions/api/v1/activeUsers/download'

// talk
export const TALK = SERVER_URL + 'events/api/v1/conference/comment'
export const GET_TALK_COMMENTS = SERVER_URL + 'events/api/v1/conference/comments'
export const TALK_COMMENT_LIKE =
	SERVER_URL + 'events/api/v1/conference/comment/like'
export const GET_TALK_LIKES = SERVER_URL + 'events/api/v1/conference/like'
export const CONFERENCE_LIKE = SERVER_URL + 'events/api/v1/conference/like'
