import React, { useState } from 'react'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useLocaleMode from '../../../hooks/useLocaleMode'
import Icon from '../../atoms/Icon/Icon'
import './searchBar.scss'

const SearchBar = props => {
	const [isShowingSearcherMobile, setIsShowingSearcherMobile] = useState(false)
	const inputref = React.useRef(null)
	const { homeLink } = useHomeLinkMode()
	const { locale } = useLocaleMode()

	const searchBarLabel = {
		'es-AR': {
			mobile: '¿En qué podemos ayudarte?',
			desktop: '¿En qué podemos ayudarte?',
		},
		'es-PY': {
			mobile: '¿En qué podemos ayudarte?',
			desktop: '¿En qué podemos ayudarte?',
		},
		'es-UY': {
			mobile: '¿En qué podemos ayudarte?',
			desktop: '¿En qué podemos ayudarte?',
		},
		'es-CO': {
			mobile: '¿Qué estás buscando?',
			desktop: '¿Qué estás buscando? Déjanos ayudarte',
		},
	}[locale || 'es-CO']

	const navigateToResults = event => {
		event.preventDefault()
		const textSearch = inputref.current.value.replace(/^\s*|\s*$/g, '')
		textSearch !== '' &&
			(window.location.href = homeLink + `resultados/?search=${textSearch}`)
	}

	const cleanSpaces = Text => {
		return Text.replace('  ', ' ')
	}

	return (
		<>
			{props.isMobile && (
				<span
					className='f-icon-activator'
					onClick={() => {
						props.setPopUpVisibility(false)
						setIsShowingSearcherMobile(true)
					}}
				>
					Buscar
				</span>
				// <Icon
				//   icon="icon-search regal-blue f-icon-activator"
				//   size="1-5"
				//   tagtype="span"
				//   onclick={() => {
				//     props.setPopUpVisibility(false);
				//     setIsShowingSearcherMobile(true);
				//   }}
				// >
				//   <p>Buscar</p>
				// </Icon>
			)}
			<div
				className={
					isShowingSearcherMobile
						? 'f-search-wrapper active'
						: 'f-search-wrapper'
				}
			>
				<form>
					<input
						type='text'
						className={isShowingSearcherMobile ? 'f-search active' : 'f-search'}
						placeholder={
							props.isMobile ? searchBarLabel?.mobile : searchBarLabel?.desktop
						}
						required='required'
						ref={inputref}
						name='search'
						onKeyPress={event => {
							inputref.current.value = cleanSpaces(inputref.current.value)
							event.key === 'Enter' && navigateToResults(event)
						}}
					/>
					<button
						type='button'
						className={
							isShowingSearcherMobile
								? 'f-button-search active'
								: 'f-button-search'
						}
						onClick={navigateToResults}
					>
						<Icon icon='icon-search' size='1-5' tagType='i' />
					</button>
				</form>
				{isShowingSearcherMobile && props.isMobile && (
					<button
						className='f-close'
						type='button'
						onClick={() => {
							setIsShowingSearcherMobile(false)
							inputref.current.value = ''
						}}
					/>
				)}
			</div>
		</>
	)
}

SearchBar.propTypes = {}

export default SearchBar
