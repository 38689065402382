import { MenuItem, Select } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import GoogleMapReact from 'google-map-react'
import React, { useEffect, useState } from 'react'
import { API_GOOGLE_KEY } from '../../../state/constants/appConstants'
import ListPoint from '../list-point/list-point'
import Point from '../point/point'
import useLocaleMode from '../../../hooks/useLocaleMode'
import Axios from 'axios'
import './map.scss'

const styleMap = {
	display: 'block',
	float: 'left',
	height: '100%',
	position: 'relative',
	width: '70%',
	margin: '0 auto',
}

const Map = () => {
	const { locale } = useLocaleMode()

	const query = useStaticQuery(graphql`
		query COUNTRIES_MAPS {
			allContentfulTenaPaises(
				sort: { fields: orden, order: ASC }
				filter: { node_locale: { eq: "es-CO" } }
			) {
				nodes {
					codigoDeDosLetras
					lenguaje
					titulo
					dominio
					nombreDepartamento
					nombreCiudad
					departamentos {
						codigo
						nombre
					}
					ciudades {
						codigo
						codigoDepartamento
						nombre
					}
					puntosDeVenta {
						lat
						lng
						name
						description
						codigoCiudad
						codigoDepartamento
					}
				}
			}
		}
	`)

	const [isChange, setIsChange] = useState(false)

	const country = query.allContentfulTenaPaises.nodes.find(
		node => node.codigoDeDosLetras === locale.slice(-2)
	)
	const [dataMaps, setDataMaps] = useState(country.puntosDeVenta)
	const [defaulGeo, setDefaulGeo] = useState({
		center: {
			lat: dataMaps[0] ? dataMaps[0].lat : null,
			lng: dataMaps[0] ? dataMaps[0].lng : null,
		},
		zoom: 10,
	})
	const [userPos, setUserPos] = useState({
		lat: dataMaps[0] ? dataMaps[0].lat : null,
		lng: dataMaps[0] ? dataMaps[0].lng : null,
	})

	const [department, setDepartment] = useState('')
	const [city, setCity] = useState('')

	useEffect(() => {
		if (!isChange) {
			navigator.geolocation.getCurrentPosition(
				position => {
					let cityAux
					Axios.get(
						'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
							position.coords.latitude +
							',' +
							position.coords.longitude +
							'&key=AIzaSyCN8ZrJwdNr5hJ4FkpgwMR6qzH1KS4TWlg'
					).then(resp => {
						const results = resp.data.results
						for (var j = 0; j < results.length; j++) {
							if (results[j].types[0] === 'locality') {
								break
							}
						}
						for (let i = 0; i < results[j].address_components.length; i++) {
							if (results[j].address_components[i].types[0] === 'locality') {
								cityAux = results[j].address_components[i]
							}
						}
						if (country.ciudades) {
							const city = country.ciudades.filter(
								(city => city.nombre === cityAux.long_name.toUpperCase()) || []
							)
							if (city[0]) {
								const auxCodeDepartment = {
									target: {
										value: city[0].codigoDepartamento,
									},
								}
								changeDepartment(auxCodeDepartment)
								setDefaulGeo({
									center: {
										lat: position.coords.latitude,
										lng: position.coords.longitude,
									},
									zoom: 16,
								})
							}
						}
						setUserPos({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						})
					})

					setIsChange(true)
				},
				() => {
					setIsChange(true)
				}
			)
		}
	}, [isChange])

	const changeDepartment = event => {
		setDepartment(event.target.value)
		setCity('')
		const dataFilter = country.puntosDeVenta.filter(info => {
			if (info.codigoDepartamento) {
				return info.codigoDepartamento.indexOf(event.target.value) > -1
			}
			return null
		})
		setDataMaps(dataFilter)
		setDefaulGeo({
			center: {
				lat: dataFilter[0] ? dataFilter[0].lat : null,
				lng: dataFilter[0] ? dataFilter[0].lng : null,
			},
			zoom: 14,
		})
	}

	const changeCity = event => {
		setCity(event.target.value)
		const dataAux = country.puntosDeVenta.filter(info => {
			if (info.codigoDepartamento) {
				return info.codigoDepartamento.indexOf(department) > -1
			}
			return null
		})
		const dataFilter = dataAux.filter(info => {
			if (info.codigoCiudad) {
				return info.codigoCiudad.indexOf(event.target.value) > -1
			}
			return null
		})
		setDataMaps(dataFilter)
		setDefaulGeo({
			center: {
				lat: dataFilter[0] ? dataFilter[0].lat : null,
				lng: dataFilter[0] ? dataFilter[0].lng : null,
			},
			zoom: 17,
		})
	}

	return (
		<div className='f-map'>
			{isChange && (
				<>
					<div id='f-map-citys' className='f-map-citys'>
						<p className='f-map-citys-copy'>{country.nombreDepartamento}</p>
						<Select
							labelId='department'
							name='select-history'
							value={department}
							onChange={changeDepartment}
						>
							<MenuItem selected value=''>
								Selecciona tu {country.nombreDepartamento}
							</MenuItem>
							{(country.departamentos || []).map(dep => (
								<MenuItem key={dep.codigo} value={dep.codigo}>
									{dep.nombre}
								</MenuItem>
							))}
						</Select>
						{country.ciudades && (
							<>
								<p className='f-map-citys-copy'>{country.nombreCiudad}</p>
								<Select
									labelId='city'
									name='select-history'
									value={city}
									onChange={changeCity}
								>
									<MenuItem selected value=''>
										Selecciona tu {country.nombreCiudad}
									</MenuItem>
									{country.ciudades
										.filter(
											(city => city.codigoDepartamento === department) || []
										)
										.map(city => (
											<MenuItem key={city.codigo} value={city.codigo}>
												{city.nombre}
											</MenuItem>
										))}
								</Select>
							</>
						)}
					</div>

					<GoogleMapReact
						style={styleMap}
						bootstrapURLKeys={{ key: API_GOOGLE_KEY }}
						center={defaulGeo.center}
						zoom={defaulGeo.zoom}
						class='f-map-content'
					>
						{dataMaps.map((data, key) => (
							<Point
								key={key}
								name={data.name}
								description={data.description}
								lat={data.lat}
								lng={data.lng}
							/>
						))}
						<Point
							user={true}
							name='Tu posicion actual'
							lat={userPos.lat}
							lng={userPos.lng}
						/>
					</GoogleMapReact>
					<ListPoint
						userPos={userPos}
						dataMap={dataMaps}
						setDefaulGeo={setDefaulGeo}
					/>
				</>
			)}
		</div>
	)
}

export default Map
