export const countries = [
    {
        name: 'COLOMBIA',
        value: 'es-CO',
        locale: 'es-CO',
        prefix: '+57',
        code: 1,
        cellDig: 10,
    },
    {
        name: 'ARGENTINA',
        value: 'es-AR',
        locale: 'es-AR',
        prefix: '+54',
        code: 9,
        cellDig: 10,
    },
    {
        name: 'BOLIVIA',
        value: 'es-BO',
        locale: 'es-BO',
        prefix: '+591',
        code: 7,
        cellDig: 8,
    },
    {
        name: 'REPÚBLICA DOMINICANA',
        value: 'es-DO',
        locale: 'es-DO',
        prefix: '+1',
        code: 13,
        cellDig: 10,
    },
    {
        name: 'ECUADOR',
        value: 'es-EC',
        locale: 'es-EC',
        prefix: '+593',
        code: 5,
        cellDig: 10,
    },
    {
        name: 'PERÚ',
        value: 'es-PE',
        locale: 'es-PE',
        prefix: '+51',
        code: 6,
        cellDig: 9,
    },
    {
        name: 'PUERTO RICO',
        value: 'es-PR',
        locale: 'es-PR',
        prefix: '+1',
        code: 12,
        cellDig: 10,
    },
    {
        name: 'CHILE',
        value: 'es-CL',
        locale: 'es-CL',
        prefix: '+56',
        code: 8,
        cellDig: 9,
    },
    {
        name: 'PARAGUAY',
        value: 'es-PY',
        locale: 'es-PY',
        prefix: '+595',
        code: 10,
        cellDig: 9,
    },
    {
        name: 'URUGUAY',
        value: 'es-UY',
        locale: 'es-UY',
        prefix: '+598',
        code: 11,
        cellDig: 8,
    },
]