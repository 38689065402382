export const getSessionStorage = () => {
	if (typeof window !== 'undefined') {
		return sessionStorage
	}
	return {}
}

export const getWindow = () => {
	if (typeof window !== 'undefined') {
		return window
	}
	return {}
}

export const getLocalStorage = () => {
	if (typeof window !== 'undefined') {
		return localStorage
	}
	return {}
}
