// import { graphql, useStaticQuery } from "gatsby";

export default function useLocaleMode() {
	// const { locale } = useIntl();
	const locale = process.env.LOCALE
	const localePath = locale + '/'
	// const defaultLanguage = process.env.LOCALE;

	// const result = useStaticQuery(
	//   graphql`
	//     query countries {
	//       allContentfulTenaPaises(
	//         sort: { fields: orden, order: ASC }
	//         filter: { node_locale: { eq: "es-CO" } }
	//       ) {
	//         nodes {
	//           id
	//           dominio
	//           codigoDeDosLetras
	//           codigoDelPais
	//           titulo
	//           lenguaje
	//           prefijoTelefonico
	//         }
	//       }
	//     }
	//   `
	// );

	// const country = defaultLanguage && result.allContentfulTenaPaises.nodes.filter(country => defaultLanguage.includes(country.codigoDeDosLetras))[0];

	const country = {
		codigoDeDosLetras: process.env.LOCALE.replace('es-', ''),
		codigoDelPais: process.env.COUNTRY_CODE,
		dominio: process.env.SITE_PDN,
		lenguaje: 'es',
		prefijoTelefonico: process.env.COUNTRY_PHONE_PREFIX,
		titulo: process.env.COUNTRY_NAME,
	}

	return { localePath, locale, country }
}
