import axios from 'axios'
import qs from 'qs'
import * as apiConstants from '../state/constants/apiConstants'
import SessionService from './session'
import useHomeLinkMode from '../hooks/useHomeLinkMode'

export const axiosInstance = axios.create()
const { homeLink } = useHomeLinkMode()

const refreshAccessToken = _originalRequest => {
	_originalRequest._retry = true

	const payload = qs.stringify({
		grant_type: 'refresh_token',
		client_id: apiConstants.CLIENT_ID,
		refresh_token: SessionService.getRefreshTokenData(),
	})

	return axiosInstance
		.post(apiConstants.REFRESH_TOKEN, payload, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
			},
		})
		.then(response => {
			if (response.status === 200) {
				// Find and replace token data
				const _currentTokenData = SessionService.getTokenData()
				if (_currentTokenData) {
					_currentTokenData.accessToken = response.data.access_token
					_currentTokenData.idToken = response.data.id_token
					// Set new token data
					localStorage.getItem('tokenData')
						? localStorage.setItem(
								'tokenData',
								JSON.stringify(_currentTokenData)
						  )
						: sessionStorage.setItem(
								'tokenData',
								JSON.stringify(_currentTokenData)
						  )

					// Replace token in original request
					_originalRequest.headers.Authorization = response.data.id_token
				}
				return axios(_originalRequest)
			}
		})
		.catch(err => {
			if (
				err.response.status === 400 &&
				err.response.data.error === 'invalid_grant'
			) {
				localStorage.clear()
				sessionStorage.clear()
				window.location.href = homeLink + '#sesion-expirada'
			} else {
				console.log(err)
			}
		})
}

// Get ip Address from jsonip
const holderVariables = {}
async function getIp() {
  if (holderVariables.ip) {
    return holderVariables.ip
  }
  if (sessionStorage.getItem("ipAddress")) {
    holderVariables.ip = sessionStorage.getItem("ipAddress")
    return holderVariables.ip
  }
  holderVariables.ip = new Promise((resolve, reject) => {
    axios
      .get(apiConstants.GET_IP_ADDRESS, { timeout: 5000 })
      .then(response => {
        resolve(response.data.ip)
      })
      .catch(() => {
        resolve('127.0.0.1')
      })
  })
  return holderVariables.ip
}

axiosInstance.interceptors.request.use(
  async config => {
    const ip = await getIp()
    sessionStorage.setItem('ipAddress', ip)
    config.headers['Ip-Address'] = ip
    return config
  },
  error => {
    return Promise.reject(error)
  }
);


// handle response error general case
axiosInstance.interceptors.response.use(
	response => {
		if (response.data.status === 500) {
			const event = new CustomEvent('errorServiceModal', {
				detail:
					'Lo sentimos, tenemos un error en este momento, inténtalo de nuevo mas tarde.',
			})
			document.dispatchEvent(event)
		}

		return response
	},
	error => {
		// Error
		const originalRequest = error.config
		if (error && error.response) {
			const {
				response: { status },
			} = error
			if (status) {
				if (status === 401) {
					// Unauthorized request: maybe access token has expired!
					return refreshAccessToken(originalRequest)
				} else {
					return Promise.reject(error)
				}
			}
		}
	}
)
