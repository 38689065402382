const printParams = params => {
  for (const option in params) {
    console.log(option, params[option])
  }
}

const pushDataLayerEvent = params => {
  window.dataLayer = window.dataLayer || []
  if (params.event !== 'Page_View') {
    setTimeout(() => {
      window.dataLayer.push(params)
    }, 500);
  } else {
      window.dataLayer.push(params)
  }

  if (process.env.ENVIRONMENT !== "PDN") {
    console.log("===PUSH  DATALAYER")
    printParams(params)
    console.log("===PUSH  DATALAYER")
  }
}

const pushGtagEvent = ({ event, params }) => {
  if (typeof window !== 'undefined') {
    window.gtag = window.gtag || function () { }
    if (event !== 'Page_View') {
      setTimeout(() => {
      window.gtag("event", event, params)
      }, 500);
    } else {
      window.gtag("event", event, params)
    }

    if (process.env.ENVIRONMENT !== "PDN") {
      console.log('=== GA EVENT: ', event)
      printParams(params)
      console.log('=== GA EVENT: ', event)
    }
  }
}

export { pushDataLayerEvent, pushGtagEvent }