import React, { useEffect, useState } from 'react'
import useModalGeneric from '../../../hooks/useModalGeneric'
import WhatsappIcon from '../../atoms/whatsapp-icon/whatsapp-icon'
import DescreetIcon from '../../../assets/images/icono-flotante-descreet.png'
import ModalRetails from '../../molecules/modal-retails/modal-retails'
import ModalFreeSamples from '../../molecules/modal-free-samples/modal-free-samples'
import ModalPov from '../../molecules/modalPov/modalPov'
import ModalSessionExpired from '../../molecules/modal-session-expired/modal-session-expired'
import Footer from './../footer/footer'
import Header from './../header/header'
import './layout.scss'
import ModalPromos from '../../molecules/modal-promos/modal-promos'
import ModalWhatsapp from '../../molecules/modal-whatsapp/modal-whatsapp'
import { useStaticQuery, graphql } from 'gatsby'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import SessionService from '../../../services/session'

const Layout = props => {
	const [modalVisibility, setModalVisibility] = useState(false)
	const [modalSession, setModalSession] = useState(false)
	const [isModalPromosVisible, setModalPromosVisibility] = useState(false)
	const [isModal, showModal, HideModal] = useModalGeneric(false)
	const [isModalWhatsapp, setIsModalWhatsapp] = useState(false)
	const [modalServiceError, setmodalServiceError] = useState(null)
	const [isSampleNav, setIsSampleNav] = useState(true)
	const [modalFreeSamples, setModalFreeSamples] = useState(false)
	const { homeLink } = useHomeLinkMode()

	const result = useStaticQuery(
		graphql`
			query home {
				allContentfulTenaHome {
					nodes {
						node_locale
						tieneModalTransversal
						redireccionModalTransversal
						imagenesModalTransversal {
							fluid(maxWidth: 600, quality: 80) {
								src
								srcSet
								base64
								aspectRatio
								sizes
							}
							description
						}
					}
				}
				allContentfulTenaPaises(
					sort: { fields: orden, order: ASC }
					filter: { node_locale: { eq: "es-CO" } }
				) {
					nodes {
						bandera {
							fluid(maxWidth: 100, quality: 100) {
								src
								srcSet
								base64
								aspectRatio
								sizes
							}
							description

						}
						id
						dominio
						codigoDeDosLetras
						titulo
						lenguaje
						prefijoTelefonico
						tenaFacebookUrl
						puntosDeVenta {
							lat
							lng
							name
							description
							codigoCiudad
							codigoDepartamento
						}
					}
				}
			}
		`
	)
	const filteredResultByCountry =
		result &&
		result.allContentfulTenaHome.nodes.filter(
			country => country.node_locale === process.env.LOCALE
		)
		const filteredResultByLocale =
		result &&
		result.allContentfulTenaPaises.nodes.filter(
			country => country.codigoDeDosLetras === process.env.LOCALE.slice(-2)
		)

	// Trigger to modal by hash
	const validateHashModal = () => {
		if (window.location.hash === '#comprar') {
			setModalVisibility(true)
		}
		if (window.location.hash === '#puntos-de-venta' && filteredResultByLocale[0].puntosDeVenta) {
			showModal()
		}
		if (window.location.hash === '#sesion-expirada') {
			setModalSession(true)
		}
		if (window.location.hash === '#asesoria-whatsapp') {
			setIsModalWhatsapp(true)
		}
	}

	const handleInputChange = () => {
		setmodalServiceError(
			<div className='f-error-service-modal-curtain'>
				<div className='f-error-service-modal-info'>
					<div
						className='f-error-service-close'
						onClick={() => setmodalServiceError(null)}
					>
						X
					</div>
					Lo sentimos, tenemos un error en este momento, inténtalo de nuevo mas
					tarde.
				</div>
			</div>
		)
	}

	useEffect(() => {
		validateHashModal()
		const modalPromos = sessionStorage.getItem('modalPromos')
		if (modalPromos === null) {
			setModalPromosVisibility(true)
			sessionStorage.setItem('modalPromos', 'showed')
		}
		window.addEventListener('hashchange', validateHashModal, false)
		document.addEventListener('errorServiceModal', handleInputChange)

		return () => {
			window.removeEventListener('hashchange', validateHashModal, false)
			document.removeEventListener('errorServiceModal', handleInputChange)
		}
	}, [])

	const pageViewDataLayer = () => {
		let location=''
		const userData = SessionService.getUserData()
		if (typeof window !== 'undefined') {
			location= window.location.href
		}
		const CryptoJS = require("crypto-js");
		function encrypt(data) {
			if (data) {
				data = CryptoJS.SHA256(data);
				data = data.toString();
				return data;
			} else {
				return undefined
			}
		}
		pushDataLayerEvent({event: 'Page_View',
		location: location,
		page: location.split('/').slice(3).join('/'),
		title: props.title || undefined,
		userId: userData ? userData.userId ? encrypt(userData.userId.toString()) : userData.usuarioId ? encrypt(userData.usuarioId.toString())  : undefined : undefined
		})
	}
	
	useEffect(() => {
	  pageViewDataLayer()
	}, [])
	

	return (
		<>
			{props.showHeader !== false && (
				<Header
					isIndex={props.isIndex || false}
					btnReturn={props.btnReturn}
					btnReturnRedirection={props.btnReturnRedirection}
					isFullPage={props.isFullPage}
					setModalVisibility={setModalVisibility}
					setModalFreeSamples={setModalFreeSamples}
					currentUser={props.currentUser}
					currentPoints={props.currentPoints}
					locale={props.locale}
					handleBannerPaused={
						props.handleBannerPaused && props.handleBannerPaused
					}
					isMobile={props.isMobile}
					isChangePassword={props.isChangePassword}
					isDescreet={props.isDescreet}
					setIsSampleNav={setIsSampleNav}
					haveFontEvents={props.haveFontEvents}
					btnReturnWhite={props.btnReturnWhite}
				/>
			)}
			<div
				className={
					`${
						props.white ? 'f-layout-background-white' : 'f-layout-background'
					} ${props.isFullPage && 'f-layout-background--fullpage'}` +
					(props.isDescreet === true ? ' f-descreet' : '') +
					(!isSampleNav && props.isFullPage !== true ? ' f-sample-hidde' : '') +
					(props.showHeader === false ? ' f-layout-background--no-header' : '')
				}
			>
				<main>{props.children}</main>
			</div>
			<Footer
				countriesData={result.allContentfulTenaPaises.nodes}
				isFullPage={props.isFullPage}
				isMobile={props.isMobile}
				isDescreet={props.isDescreet}
			/>
			{props.btnWhatsapp ? null : <WhatsappIcon />}
			{props.isDescreet && (
				<a href={homeLink + 'registro'} className='f-icon-descreet'>
					<img src={DescreetIcon} alt='descreet icon' />
				</a>
			)}
			{modalServiceError}
			{modalVisibility && (
				<ModalRetails
					hideAction={() => {
						setModalVisibility(false)
					}}
				/>
			)}
			{modalFreeSamples && (
				<ModalFreeSamples
					hideAction={() => {
						setModalFreeSamples(false)
					}}
				/>
			)}
			{isModalPromosVisible &&
				filteredResultByCountry[0].tieneModalTransversal && (
					<ModalPromos
						content={filteredResultByCountry[0]}
						closeAction={() => {
							setModalPromosVisibility(false)
						}}
					/>
				)}
			{isModal && !modalVisibility && !modalSession && (
				<ModalPov onClose={HideModal} />
			)}
			{modalSession && !modalVisibility && !isModal && (
				<ModalSessionExpired
					onClose={() => {
						setModalSession(false)
					}}
				/>
			)}
			{isModalWhatsapp && !modalSession && !modalVisibility && !isModal && (
				<ModalWhatsapp
					hiddeAction={() => {
						setIsModalWhatsapp(false)
						window.location.hash = ''
					}}
				/>
			)}
		</>
	)
}

export default Layout
