import React from 'react'
import useBrowserMode from '../../../hooks/useBrowserMode'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import Icon from '../../atoms/Icon/Icon'
import AccountInfo from '../accountInfo/accountInfo'
import * as constants from '../../../state/constants/appConstants'
import './signInUser.scss'
import Button from '../../atoms/Button/Button'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'

const SignInUser = props => {
	const [currentFontSize, setCurrentFontsize] = React.useState(
		constants.MIN_FONT
	)
	const { isBrowser } = useBrowserMode()
	const { homeLink } = useHomeLinkMode()
	const {
		setPopUpVisibility,
		isPopUpVisible,
		loggedUser,
		setLayoutVisible,
		loggedUserPoints,
		handleCloseSession,
	} = props
	const html = isBrowser && document.getElementsByTagName('html')[0]

	React.useEffect(() => {
		const storageFontSize = localStorage.getItem('fontSize')
		if (storageFontSize) {
			setCurrentFontsize(Number(storageFontSize))
			html.style.fontSize = `${Number(storageFontSize)}px`
		}
	}, [])
	// console.log(props)
	const handleChangeSize = _newFontSize => {
		setCurrentFontsize(_newFontSize)
		html.style.fontSize = `${_newFontSize}px`
		localStorage.setItem('fontSize', _newFontSize)
	}

	const userInfo=dataLayerUserInfo()
	
	const dataLaterButton = option => {
		const category=option
		let location=''
		if (typeof window !== 'undefined') {
			location= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Menú',
		category,
		ation: 'click',
		label: category,
		sub_category: 'ninguno',
		location, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}

	const dataLayerRegistro = (link) => {
		let host=''
		if (typeof window !== 'undefined') {
			host= window.location.hostname.split('www.')[0]
		}
		pushDataLayerEvent({
			event: "cta_click",
			link_text: "REGÍSTRATE",
			link_url: link,
			link_domain: host,
			link_id: "",
			link_classes: ""
		})
	}

	const dataLayerLogin = () => {
		
		pushDataLayerEvent({
			event: "login",
			user_id: undefined,
			
		})
	}

	return (
		<>
			{(props.isFullPage === undefined || !props.isFullPage) && isBrowser ? (
				<div className='f-user-main-container events'>
					{!props.isMenuActive && props.isMobile ? (
						<p
							className='f-icon-user'
							onClick={() =>
								!loggedUser
									? setPopUpVisibility(!isPopUpVisible)
									: setLayoutVisible(true)
							}
						>
							<Icon icon='icon-user-alt' size='1-5' tagType='span' />
						</p>
					) : (
						<div className='f-singin-content'>
							{!props.isMenuActive && (
								<div className='f-btn-container'>
									<button
										onClick={() => {
											handleChangeSize(currentFontSize - 2)
										}}
										className={`${
											currentFontSize <= constants.MIN_FONT
												? 'f-btn-decrease f-btn-disabled'
												: 'f-btn-decrease'
										}`}
									>
										A-
									</button>
									<button
										onClick={() => {
											handleChangeSize(currentFontSize + 2)
										}}
										className={`${
											currentFontSize >= constants.MAX_FONT
												? 'f-btn-increase f-btn-disabled'
												: 'f-btn-increase'
										}`}
									>
										A+
									</button>
								</div>
							)}
							{!loggedUser ? (
								<div className='f-user-wrapper'>
									<Button
										onClickAction={() => {
											dataLaterButton(props.megaMenuCopies.login)
											dataLayerLogin()
											window.location.href = homeLink + 'autenticacion/'
										}}
										text={props.megaMenuCopies.login}
										type='button'
										classname='f-button f-button--primary f-button--rounded'
									/>
									<Button
										onClickAction={() => {
											dataLaterButton(props.megaMenuCopies.register)
											dataLayerRegistro(homeLink + 'registro/')
											window.location.href = homeLink + 'registro/'
										}}
										text={props.megaMenuCopies.register}
										type='button'
										classname='f-button f-button--secondary f-button--rounded'
									/>
								</div>
							) : (
								<AccountInfo
									currentUser={props.currentUser}
									currentPoints={props.currentPoints}
									isChangePassword={props.isChangePassword}
									handleCloseSession={handleCloseSession}
									loggedUserPoints={loggedUserPoints}
								/>
							)}
						</div>
					)}
				</div>
			) : props.isFullPage && props.isMobile ? null : (
				props.isFullPage && (
					<div className='f-user-main-container f-user-main-container--fullpage events'>
						{props.haveFontEvents !== false && (
							<div className='f-btn-container'>
								<button
									onClick={() => {
										handleChangeSize(currentFontSize - 2)
									}}
									className={`${
										currentFontSize <= constants.MIN_FONT
											? 'f-btn-decrease f-btn-disabled'
											: 'f-btn-decrease'
									}`}
								>
									A-
								</button>
								<button
									onClick={() => {
										handleChangeSize(currentFontSize + 2)
									}}
									className={`${
										currentFontSize >= constants.MAX_FONT
											? 'f-btn-increase f-btn-disabled'
											: 'f-btn-increase'
									}`}
								>
									A+
								</button>
							</div>
						)}

						<div className='f-menu-button'>
							<button
								id='f-header-burger-menu'
								className={'f-header-burguer f-header-burguer--active'}
								type='button'
								onClick={() => {
									props.toggleMenu(true)
								}}
							>
								<span
									className={
										'f-header-burguer__line f-header-burguer__line--active'
									}
								></span>
								<span
									className={
										'f-header-burguer__line f-header-burguer__line--active'
									}
								></span>
								<span
									className={
										'f-header-burguer__line f-header-burguer__line--active'
									}
								></span>
							</button>
							<p className='f-menu-button-copy'>Cerrar</p>
						</div>
					</div>
				)
			)}
		</>
	)
}

export default SignInUser
