import React from 'react'
import PropTypes from 'prop-types'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import './countriesList.scss'

const CountriesList = props => {
	const dataLayerCountry = (country, lenguaje) => {
		pushDataLayerEvent({
			event: 'geo_selector',
			website_country: country,
			website_language: lenguaje
		})
	}
	return (
		<div className='f-list-items'>
			<ul className='f-countries-column'>
				{props.countriesData.map(item => (
					<li
						className={
							item.titulo.toUpperCase() === process.env.COUNTRY_NAME
								? 'f-nav-link-country f-nav-link-country-selected'
								: 'f-nav-link-country'
						}
						key={item.id}
					>
						<a href={item.dominio} onClick={()=> dataLayerCountry(item.codigoDeDosLetras, item.lenguaje)}>{item.titulo}</a>
					</li>
				))}
			</ul>
			<span onClick={props.openHideDropdown} className='f-countries-close'>
				X
			</span>
		</div>
	)
}

CountriesList.propTypes = {
	countriesList: PropTypes.array,
	fCloseClass: PropTypes.string,
}

export default CountriesList
