import React from 'react'
import './samples-bar-header.scss'
import Button from '../../atoms/Button/Button'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import { isPyAnduY } from '../../../utils/verifyPyAndUy'
const SamplesBarHeader = props => {
	
	const userInfo=dataLayerUserInfo()
	const dataLaterSamples = () => {		
		let location=''
		if (typeof window !== 'undefined') {
			location= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Productos',
		category: 'productos',
		action: 'click',
		label:' Muestra Gratis',
		location: location, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}
	return (
		<div
			className={
				props.countryHaveSamples
					? props.samplesBarVisibility
						? 'f-samples-header'
						: 'f-samples-header f-samples-header--hide'
					: 'f-samples-header f-samples-header--hide'
			}
		>
			<div className='f-samples-header-content'>
				{props.countryHaveSamples && !isPyAnduY ? (
					<>
						<p>{props.barText}</p>
						<Button
							type='button'
							classname={
								props.samplesBarVisibility
									? 'f-button f-button--success'
									: 'f-button f-button--success f-button--hide'
							}
							text={props.samplesText}
							value={props.samplesText}
							onClickAction={() =>
								{(window.location.href =
									props.homeLink + props.samplesRedirection)
									dataLaterSamples()
								}
							}
						/>
					</>
				) : null}
			</div>
		</div>
	)
}

export default SamplesBarHeader
