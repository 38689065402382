// import useBrowserMode from "./useBrowserMode";
// import useLocaleMode from "./useLocaleMode";

export default function useHomeLinkMode() {
	// const { isBrowser } = useBrowserMode();
	// const { localePath } = useLocaleMode();
	// const homeLink = isBrowser ? '//' + window.location.host + '/' : '/';
	const homeLink =
		process.env.ENVIRONMENT === 'PDN'
			? process.env.SITE_PDN + '/'
			: process.env.ENVIRONMENT === 'STG'
			? process.env.SITE_STG + '/'
			: process.env.SITE_DEV + '/'
	return { homeLink }
}
