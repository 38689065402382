import React from 'react'

export default function useMobileMode() {
	const [isMobile, setMobileBool] = React.useState(false)
	const [currentPage, setCurrentPage] = React.useState('')

	const updateDimensions = () => {
		setMobileBool(window.innerWidth < 1024)
	}

	React.useEffect(() => {
		updateDimensions()
		if (currentPage && currentPage !== 'productos') {
			setCurrentPage('productos')
		}
		window.addEventListener('resize', updateDimensions)
		return () => {
			window.removeEventListener('resize', updateDimensions)
		}
	}, [])

	return { isMobile, currentPage }
}
