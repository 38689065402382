import { axiosInstance } from './axiosInstance'

import * as constants from '../state/constants/apiConstants'

class RegisterService {
	static firstRegister(data) {
		// return axiosInstance.post(constants.REGISTER_USER_PATH, data).then(resp => resp.data);
		return axiosInstance
			.post(constants.REGISTER_USER_PATH, data)
			.then(resp => resp.data)
	}

	static checkEmail(data) {
		return axiosInstance
			.post(constants.REGISTER_EMAIL, data)
			.then(resp => resp.data)
	}

	// Hace validacion con briteverify  y base de datos
	static validateEmail(email) {
		return axiosInstance
			.get(constants.ACCOUNT_VALIDATE_EMAIL, { params: { email } })
			.then(resp => resp.data)
	}

	static checkPhone(data) {
		return axiosInstance
			.post(constants.REGISTER_CELLPHONE, data)
			.then(resp => resp.data)
	}

	static validateEmailAndCell(payload) {
		return axiosInstance
			.post(constants.VALIDATE_EMAIL_CELL, payload)
			.then(resp => resp.data)
	}

	static checkDocument(data) {
		return axiosInstance
			.post(constants.REGISTER_DOCUMENT, data)
			.then(resp => resp.data)
	}

	static updateRoles(data) {
		return axiosInstance
			.put(constants.REGISTER_USER_PATH, data)
			.then(resp => resp.data)
	}

	static registerUser(data) {
		// commented for new register
		// return axiosInstance.post(constants.REGISTER_PATH, data).then(resp => resp.data);
		return axiosInstance
			.post(constants.REGISTER_PATH, data)
			.then(resp => resp.data)
	}

	static confirmUser(data) {
		return axiosInstance
			.post(constants.CONFIRMATION_REGISTER, data)
			.then(resp => resp.data)
	}

	static resendCode(data) {
		return axiosInstance
			.post(constants.REGISTER_RESEND_CODE, data)
			.then(resp => resp.data)
	}

	static checkInstitutionId(data) {
		return axiosInstance
			.post(constants.REGISTER_ACCOUNT_INSTITUTION_ID, data)
			.then(resp => resp.data)
	}

	static fullConfirmationUrl(code) {
		return axiosInstance
			.get(constants.CONFIRMATION_URL + code)
			.then(resp => resp.data)
	}

	static getInfoMigratedUserByID(id) {
		return axiosInstance
			.get(constants.GER_MIGRATED_INFO + id)
			.then(resp => resp.data)
	}

	static registerLeaflet(payload) {
		return axiosInstance
			.post(constants.LEAFLET_REGISTER, payload)
			.then(resp => resp.data)
	}

	static registerLeafletCallcenter(payload, options = {}) {
		return axiosInstance
			.post(constants.LEAFLET_REGISTER_CALLCENTER, payload, options)
			.then(resp => resp.data)
	}

	static updateUserWithoutToken(payload) {
		return axiosInstance
			.put(constants.UPDATE_USER_WITHOUT_TOKEN, payload)
			.then(resp => resp.data)
	}

	static activeAccount(payload) {
		return axiosInstance
			.put(constants.ACTIVE_ACCOUNT, null, { params: payload })
			.then(resp => resp.data)
	}
}

export default RegisterService
