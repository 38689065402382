import PropTypes from 'prop-types'
import React from 'react'
import useBrowserMode from '../../../hooks/useBrowserMode'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import tenaLogo from './../../../assets/images/logo_tena.webp'
import './logo.scss'

const Logo = props => {
	const { homeLink } = useHomeLinkMode()
	const { isBrowser } = useBrowserMode()

	return (
		<div className='f-logo-container'>
			<div className='f-logo-container-image'>
				{isBrowser && (
					<a
						href={
							props.adviserActive
								? props.adviserActive()
									? homeLink + 'callcenter'
									: homeLink
								: homeLink
						}
					>
						<img src={tenaLogo} alt='Logo TENA' />
					</a>
				)}
			</div>
		</div>
	)
}

Logo.propTypes = {
	name: PropTypes.string,
}

export default Logo
