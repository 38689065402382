import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import useBrowserMode from '../../../hooks/useBrowserMode'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import useLocaleMode from '../../../hooks/useLocaleMode'
import fbIcon from './../../../assets/images/facebook-logo.svg'
import igIcon from './../../../assets/images/instagram-logo.svg'
import ytIcon from './../../../assets/images/youtube-logo.svg'
import celularIconWhite from './../../../assets/images/icon-celular-white.svg'
import logoDescreet from './../../../assets/images/logo-discreet.svg'
import LightBox from './../../organisms/lightbox/lightbox'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { convertText } from '../../../utils/convertFirstCharacterToUppercase'
import { isPyAnduY } from '../../../utils/verifyPyAndUy'
import './footer.scss'

const Footer = props => {
	const { locale } = useLocaleMode()
	const { homeLink } = useHomeLinkMode()
	const { isBrowser } = useBrowserMode()
	const lightBoxUsage = 'countriesList'
	const [listOpen, setCountriesState] = React.useState(false)
	const [fCloseClass, setClass] = React.useState('')

	const currentFlag = props.countriesData.filter(
		country => country.titulo.toUpperCase() === process.env.COUNTRY_NAME
	)[0].bandera

	const facebook_tena_url = props.countriesData.filter(
		country => country.titulo.toUpperCase() === process.env.COUNTRY_NAME
	)[0].tenaFacebookUrl

	const openHideDropdown = () => {
		if (listOpen === false) {
			setCountriesState(true)
		} else {
			setClass('f-closed-dropdown')
			setTimeout(() => {
				setCountriesState(false)
				setClass('')
			}, 200)
		}
	}

	const result = useStaticQuery(graphql`
		query InfoFooter {
			allContentfulTenaHome {
				nodes {
					footerInformacionAdicionalDos
					footerInformacionAdicionalTres
					footerInformacionAdicionalUno
					footerInformacionAdicionalCuatro
					footerNumeroLineaDeAtencion
					footerRedireccionDos
					footerRedireccionTres
					footerRedireccionCuatro
					footerTituloLineaDeAtencion
					footerRedireccionUno
					footerLineaDeAtencionRegistro
					footerBrandLogo {
						file {
							url
						}
						title
					}
					node_locale
					tieneInstagram
				}
			}
		}
	`)

	const footerInfo =
		result &&
		isBrowser &&
		result.allContentfulTenaHome.nodes.filter(
			node => node.node_locale === locale
		)[0]

	const {
		footerTituloLineaDeAtencion,
		footerNumeroLineaDeAtencion,
		footerInformacionAdicionalUno,
		footerInformacionAdicionalDos,
		footerInformacionAdicionalTres,
		footerInformacionAdicionalCuatro,
		footerRedireccionDos,
		footerRedireccionTres,
		footerRedireccionCuatro,
		footerRedireccionUno,
		footerLineaDeAtencionRegistro,
		footerBrandLogo,
		tieneInstagram,
	} = footerInfo

	const dataLayerPhone = () => {
		pushDataLayerEvent({
			event: 'phone_click',
		})
	}
	const dataLayerFooter = (text, position) => {
		pushDataLayerEvent({
			event: 'menu_click',
			action_type: 'Link',
			link_text: text,
			index: position,
			menu_name: 'menu del footer',
		})
	}

	return (
		<>
			{listOpen && (
				<LightBox
					openHideDropdown={openHideDropdown}
					classToAdd={fCloseClass}
					lightBoxUsage={lightBoxUsage}
					countriesData={props.countriesData}
				/>
			)}
			{footerInfo && (
				<footer
					className={
						`f-footer-wrapper ${
							props.isFullPage ? 'f-footer-wrapper--fullpage' : ''
						}` + (props.isDescreet ? ' f-descreet' : '')
					}
				>
					{props.isFullPage ? (
						<div className='f-footer-info-container'>
							<div className='f-phone-info-wrapper'>
								{locale !== 'es-BO' && locale !== 'es-DO' ? (
									<>
										<img
											src={celularIconWhite}
											alt='imagen de linea de atencion'
										/>
										<div>
											<p>
												{props.isMobile
													? footerTituloLineaDeAtencion
													: footerLineaDeAtencionRegistro}
											</p>
											<a
												href={`tel://${footerNumeroLineaDeAtencion}`}
												className='f-btn-nav-type'
												rel='nofollow'
												onClick={() => dataLayerPhone()}
											>
												{footerNumeroLineaDeAtencion}
											</a>
										</div>
									</>
								) : null}
							</div>
						</div>
					) : (
						<>
							<div className='f-footer-info-container'>
								<div className='f-phone-countries'>
									{props.isDescreet ? (
										<a
											className='f-select-container'
											href={homeLink + 'discreet/'}
										>
											<img
												src={logoDescreet}
												alt='logo descreet'
												className='f-logo-descreet'
											/>
										</a>
									) : (
										<div className='f-select-container'>
											<span
												onClick={openHideDropdown}
												className={
													listOpen
														? 'f-select-title f-select-title--active'
														: 'f-select-title'
												}
											>
												<Img
													className='f-image-flag'
													fluid={currentFlag.fluid}
													alt={currentFlag.description}
												/>
												<label htmlFor=''>{process.env.COUNTRY_NAME}</label>
											</span>
										</div>
									)}
									<div className='f-phone-info-wrapper'>
										{locale !== 'es-BO' && locale !== 'es-DO' ? (
											<>
												<img
													src={celularIconWhite}
													alt='imagen de linea de atencion'
												/>
												<div>
													<p>{footerTituloLineaDeAtencion}</p>
													<a
														href={`tel://${footerNumeroLineaDeAtencion}`}
														className='f-btn-nav-type'
														rel='nofollow'
														onClick={() => dataLayerPhone()}
													>
														{footerNumeroLineaDeAtencion}
													</a>
												</div>
											</>
										) : null}
									</div>
								</div>
								<div className='f-social'>
									{!isPyAnduY && (
										<a
											target='_blank'
											href={
												props.isDescreet
													? 'https://www.facebook.com/masvivasquenunca'
													: facebook_tena_url
													? facebook_tena_url
													: 'https://www.facebook.com/TenaBienEstar'
											}
											rel='noreferrer'
										>
											<img src={fbIcon} alt='icono facebook' />
										</a>
									)}

									{tieneInstagram && !isPyAnduY && (
										<a
											target='_blank'
											href={
												props.isDescreet
													? 'https://instagram.com/masvivas?igshid=1u5l2xtzxto42'
													: 'https://www.instagram.com/masvivas/'
											}
											rel='noreferrer'
										>
											<img src={igIcon} alt='icono instagram' />
										</a>
									)}
									{!isPyAnduY && (
										<a
											target='_blank'
											href={
												props.isDescreet
													? 'https://www.youtube.com/channel/UCIDfwvhgDLjd9uPrXi6sW_w'
													: 'https://www.youtube.com/channel/UC0HCMvbKjfj0cnpEalVlG2w'
											}
											rel='noreferrer'
										>
											<img src={ytIcon} alt='icono Youtube' />
										</a>
									)}
								</div>
							</div>
							<div className='f-footer-others-container'>
								<div className='f-brand-logo'>
									{footerInfo && (
										<ul className='f-additional-pages'>
											<li>
												<a
													className='f-footer-nav-link'
													title={footerInformacionAdicionalUno}
													href={homeLink + footerRedireccionUno}
													onClick={() =>
														dataLayerFooter(footerInformacionAdicionalUno, 1)
													}
												>
													{convertText(footerInformacionAdicionalUno)} |
												</a>
											</li>
											<li>
												<a
													className='f-footer-nav-link'
													title={footerInformacionAdicionalDos}
													href={homeLink + footerRedireccionDos}
													onClick={() =>
														dataLayerFooter(footerInformacionAdicionalDos, 2)
													}
												>
													{convertText(
														footerInformacionAdicionalDos +
															(!props.isMobile ? ' | ' : '')
													)}
												</a>
											</li>
											<li>
												<a
													className='f-footer-nav-link'
													title={footerInformacionAdicionalTres}
													href={homeLink + footerRedireccionTres}
													onClick={() =>
														dataLayerFooter(footerInformacionAdicionalTres, 3)
													}
												>
													{convertText(
														footerInformacionAdicionalTres +
															(!props.isMobile ? ' | ' : '')
													)}
												</a>
											</li>
											<li>
												<a
													className='f-footer-nav-link'
													title={footerInformacionAdicionalCuatro}
													href={homeLink + footerRedireccionCuatro}
													onClick={() =>
														dataLayerFooter(footerInformacionAdicionalCuatro, 4)
													}
												>
													{convertText(footerInformacionAdicionalCuatro)}
												</a>
											</li>
										</ul>
									)}
									{process.env.LOCALE === 'es-BO' && (
										<div>
											<p className='f-footer-description'>
												Essity es una compañía líder en el mercado global del
												sector de higiene y salud dedicada a mejorar el
												bienestar a través de sus productos y servicios. Los
												productos de Essity se venden aproximadamente en 150
												países bajo las marcas globales TENA® y Tork®, la marca
												local Colhogar® y otras marcas importantes como
												Actimove®, JOBST®, Leukoplast®, Libero®, Libresse®,
												Lotus®, Nosotras®, Saba®, Tempo®, TOM Organic®, Vinda® y
												Zewa®. Essity cuenta con unos 46 000 empleados. Las
												ventas netas de 2021 ascendieron aproximadamente a 122
												000 millones de coronas suecas (12 000 millones de
												euros). Su sede se encuentra en Estocolmo (Suecia) y
												cotiza en el mercado de valores Nasdaq de Estocolmo.
												Essity rompe barreras por el bienestar y contribuye con
												una sociedad saludable, sostenible y circular. Más
												información en{' '}
												<a href='http://www.essity.com/'>www.essity.com</a>.
											</p>
										</div>
									)}
								</div>

								<div className='f-brand-container'>
									<img
										src={footerBrandLogo.file.url}
										alt={footerBrandLogo.title}
										loading='lazy'
									/>
									<small>© 2020 Todos los derechos reservados</small>
								</div>
							</div>
						</>
					)}
				</footer>
			)}
		</>
	)
}

export default Footer
