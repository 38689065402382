import React from 'react'
import './alertMessagge.scss'

const AlertMessagge = ({
	icon,
	messagge,
	description,
	type,
	button,
	descriptionRender,
}) => {
	return (
		<div className={`f-alert f-alert--${type}`}>
			{icon}
			{messagge && <h2 className='f-h2'>{messagge}</h2>}
			{description && <p className='f-p'>{description}</p>}
			{descriptionRender && descriptionRender}
			{button && button}
		</div>
	)
}

export default AlertMessagge
