import React from 'react'
import iconSmile from '../../../assets/images/icon-user-lonchmara.svg'
import Icon from '../../atoms/Icon/Icon'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import './accountInfo.scss'
import useBrowserMode from '../../../hooks/useBrowserMode'
import useLocaleMode from '../../../hooks/useLocaleMode'
import { formatNumber } from '../../../state/utils/utilities-format-number'
import SessionService from '../../../services/session'

const AccountInfo = ({
	handleCloseSession,
	isChangePassword,
	currentPoints,
	currentUser,
}) => {
	const { homeLink } = useHomeLinkMode()
	const { isBrowser } = useBrowserMode()
	const { locale } = useLocaleMode()
	const currentUserPoints = currentPoints || SessionService.getUserPoints()
	const currentUserName = currentUser || SessionService.getUserName()
	const CurrentdatePointsToExpire = new Date(SessionService.getUserData().datePointsToExpire) || ''

	const formatDateNumber = (date) => {
		const day = date.getDate()
		const month = date.getMonth() + 1
		const year = date.getFullYear()

		if (month < 10) {
			return (`${day}/0${month}/${year}`)
		} else {
			return (`${day}/${month}/${year}`)
		}
	}
	return (
		<>
			{isBrowser && (
				<div className='f-userLogged-wrapper'>
					<div className='f-userLogged-info'>
						{currentUserName && currentUserName.length <= 8 ? (
							<img
								src={iconSmile}
								alt='Icono sonrisa.'
								className='f-icon-smile'
							/>
						) : null}
						<p>
							Hola,
							<br />
							<a href={`${homeLink}mi-cuenta/datos-personales`}>
								{currentUserName || ''}
							</a>
						</p>
					</div>
					<div className='f-userLogged-points'>
						{locale === 'es-CO' ? (
							<>
								<p className='f-points'>
									<Icon tagtype='i' icon='icon-star' size='1' />{' '}
									{formatNumber(currentUserPoints || 0)} puntos
								</p>
								{currentUserPoints > 0 && !isNaN(CurrentdatePointsToExpire.getDate()) ?
									<p className='f-points-date'>Vencen: {formatDateNumber(CurrentdatePointsToExpire, true)}</p>
									: null
								}
							</>
						) : null}
						<p
							className={!isChangePassword ? 'f-close' : 'f-close events'}
							onClick={handleCloseSession}
						>
							<Icon tagtype='i' icon='icon-sesion-close' size='1' /> Cerrar
							sesión
						</p>
					</div>
					<div></div>
				</div>
			)}
		</>
	)
}

export default AccountInfo
