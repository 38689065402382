import PropTypes from 'prop-types'
import React from 'react'
import CountriesList from './../../molecules/countriesList/countriesList'

const Lightbox = props => {
	return (
		<div
			id='lightbox'
			className={
				props.lightBoxUsage === 'countriesList'
					? 'f-list-wrapper f-country-style ' + props.classToAdd
					: 'f-list-wrapper ' + props.classToAdd
			}
		>
			{props.lightBoxUsage === 'countriesList' ? (
				<CountriesList
					openHideDropdown={props.openHideDropdown}
					countriesData={props.countriesData}
				/>
			) : null}
		</div>
	)
}

Lightbox.propTypes = {
	countriesData: PropTypes.object,
	classToAdd: PropTypes.string,
	lightBoxUsage: PropTypes.string,
}

export default Lightbox
