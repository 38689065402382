import { localStorage, sessionStorage } from '../state/utils/storage'
import useBrowserMode from '../hooks/useBrowserMode'
import RegisterService from '../services/register'

const { isBrowser } = useBrowserMode()
const USER_DATA = 'userData'
const TOKEN_DATA = 'tokenData'
// const MIGRATION_DATA = "migrationData";
const MIGRATION_DATA = 'muid'
const USER_NAME = 'userName'
const SAMPLES = 'samples'

const findStorage = key => {
	return isBrowser && (localStorage.getItem(key) || sessionStorage.getItem(key))
}

const getUserData = () => {
	return findStorage(USER_DATA)
}

const getMigrationData = () => {
	if (findStorage(MIGRATION_DATA)) {
		return RegisterService.getInfoMigratedUserByID(
			atob(findStorage(MIGRATION_DATA))
		).then(res => {
			if (res.status === 200) {
				return res.data
			} else {
				console.log(res)
				return null
			}
		})
	} else {
		return Promise.resolve(null)
	}
}

const getTokenData = () => {
	return findStorage(TOKEN_DATA)
}

const getIdTokenData = () => {
	const token = getTokenData()
	return token && token.idToken
}

const getRefreshTokenData = () => {
	const token = getTokenData()
	return token && token.refreshToken
}

const getUserName = () => {
	return findStorage(USER_NAME)
}
const getUserPoints = () => {
	const data = getUserData()
	return data && data.points
}

const haveMainDirection = () => {
	const userData = getUserData()
	return userData && userData.mainCity && userData.mainAddress
}

const haveSecondDirection = () => {
	const userData = getUserData()
	return userData && userData.secondCity && userData.secondAddress
}

const isMigrationUser = migrationData => {
	return (
		migrationData &&
		(migrationData.institutionResponsable === null &&
			(migrationData.roles &&
				migrationData.roles.filter(rol => rol.rolId !== 9).length > 0))
	)
}

const getSamples = () => {
	return findStorage(SAMPLES)
}

const SessionService = {
	getUserData,
	getMigrationData,
	getTokenData,
	getIdTokenData,
	getRefreshTokenData,
	getUserPoints,
	getUserName,
	haveMainDirection,
	haveSecondDirection,
	isMigrationUser,
	getSamples,
}

export default SessionService
