import React from 'react'
import './whatsapp-icon.scss'
import useLocaleMode from '../../../hooks/useLocaleMode'
import { useStaticQuery, graphql } from 'gatsby'
import IconWhatsapp from '../../../assets/images/whatsapp-icon.png'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
// import useBrowserMode from '../../../hooks/useBrowserMode';

const WhatsappIcon = props => {
	const { locale } = useLocaleMode()
	// const { isBrowser } = useBrowserMode();
	const query = useStaticQuery(graphql`
		query WHATSAPP_NUMBER {
			allContentfulTenaHome {
				nodes {
					numeroDeWhatsApp
					node_locale
					tieneChat
					imagenChatWhatsApp {
						file {
							url
						}
					}
				}
			}
		}
	`)
    const userInfo=dataLayerUserInfo()
    const dataLayerWhatsapp =()=>{
        let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
        pushDataLayerEvent({event: 'Interacciones - Asesoría',
		category: 'chat',
		action: 'click',
		label: 'WhatsApp',
		location: loc,
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})

		pushDataLayerEvent({
			event: 'chat',
			action_type: 'Send message'
		})
	}

	const whatsappNumber =
		query &&
		query.allContentfulTenaHome.nodes.filter(node =>
			node.node_locale.includes(locale)
		)[0]

	return (
		<>
			{whatsappNumber &&
				(whatsappNumber.tieneChat && (
					<a
						className='f-whatsApp-container'
						href='#asesoria-whatsapp'
						// target="_blank"
						rel='noopener noreferrer'
						data-label={'Nos encantaría ayudarte, ¡conversemos!'}
						onClick={dataLayerWhatsapp}
					>
						<img
							src={
								whatsappNumber.imagenChatWhatsApp.length > 0
									? whatsappNumber.imagenChatWhatsApp[0].file.url
									: IconWhatsapp
							}
							alt='Whatsapp'
							className='f-icon-whatsapp'
						/>
					</a>
				))}
		</>
	)
}

export default WhatsappIcon
