import React from 'react'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import AlertMessagge from '../alertMessagge/alertMessagge'
import iconWarning from '../../../assets/images/icon-warning.svg'
import Button from '../../atoms/Button/Button'
import './modal-session-expired.scss'

const ModalSessionExpired = ({ onClose }) => {
	return (
		<LayoutModal onClose={onClose}>
			<section className='f-modalsession'>
				<div className='f-modalsession-content'>
					<AlertMessagge
						icon={<img src={iconWarning} alt='error-icon' />}
						messagge='Lo sentimos'
						description='Su sesión ha expirado.'
						type='warning'
						button={
							<Button
								type='button'
								text='ACEPTAR'
								onClickAction={onClose}
								classname='f-button f-button--primary'
							/>
						}
					/>
				</div>
			</section>
		</LayoutModal>
	)
}

export default ModalSessionExpired
