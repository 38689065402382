import {countries} from './countriesList'

const getUserInfo=()=>{    
    return typeof window !== 'undefined'
        ? localStorage.getItem('userData') !== null
        ? { ...JSON.parse(localStorage.getItem('userData')) }
        : null
        : null
      
}
const userData = getUserInfo() || '';
const buscarPais= (code) => {
    return countries.filter((ubicacion)=> ubicacion.code === code,)[0]||{}
}

const CryptoJS = require("crypto-js");
function encrypt(data) {
    if (data) {
        data = CryptoJS.SHA256(data);
        data = data.toString();
        return data;
    } else {
        return undefined
    }
}

const dataLayerUserInfo = () => {
    let name, lastName, cellPhone, email, city, countryState, log_status
    if(userData){
        name= userData.name||'';
        lastName= userData.lastName||'';
        cellPhone= userData.cellPhone||'';
        email= userData.email||'';
        city= userData.mainCityName||'';
        countryState= userData.mainCountryStateName||'';


        const user_data= [{
            nombre: encrypt(name),
            apellido: encrypt(lastName),
            celular: encrypt(cellPhone),
            correo: encrypt(email),
            ciudad: encrypt(city),
            departamento: encrypt(countryState),
        }]
        log_status= 'Yes'
        return {user_data, log_status}

    }else{
        log_status='No';
        return {log_status}
    }
}
export { dataLayerUserInfo}